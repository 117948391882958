import { faCloudArrowUp, faComments, faFile, faFilePdf, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { PageContext } from '../../../context/pageContext';
import clienteAxios from '../../../config/axios';
import { FormattedMessage } from 'react-intl';

function obtenerFechaHoy() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Ajustar el mes y el día a dos dígitos si es necesario
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }

    // Formatear la fecha como yyyy/mm/dd
    const formattedDate = year + '-' + month + '-' + day;
    
    return formattedDate;
}

const fechaHoy = obtenerFechaHoy();

function Tickets() {
    const navigate = useNavigate()
    const { CustomerClientId } = useParams()
    const [ online24 ] = useContext(PageContext)
    const [userData, setUserData] = useState({});
	const [products, setProducts] = useState([]);
	const [tickets, setTickets] = useState([]);
	const [hiddenTicket, setHiddenTicket] = useState(true);
	const [heightTicket, setHeightTicket] = useState(false);
	const [prevFiles, setPrevFiles] = useState([{type: '', url: "", name: ''}]);
	const [files, setFiles] = useState([]);
	const [ticket, setTicket] = useState({
        product: ''
    })
    const [filteredTickets, setFilteredTickets] = useState([]);
	const [content, setContent] = useState('');
	const [filterData, setFilterData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [ticketsPerPage] = useState(5);
	const [inProcess, setInProcess] = useState(false)

    useEffect(() => {
        let localUserData = localStorage.getItem('userData');
        if (localUserData) {
            localUserData = JSON.parse(localUserData);
            const findUserCompany = localUserData.find(data => data.CustomerClientId === CustomerClientId)

            if(findUserCompany) {
                setUserData(findUserCompany)
            }
            
        } else {
            const currentURL = window.location.pathname;
            localStorage.setItem('prevUrl', currentURL)
            navigate('/login')
        }

    }, [CustomerClientId])


    useEffect(() => {

        const consultaApi = async () => {
            try {
                const res = await clienteAxios.get(`/api/instance/${online24.id}/service/24ONLINE/type/${userData.CustomerClientType}/client/${userData.CustomerClientId}/tickets/${currentPage}/${ticketsPerPage}`)
                if(res.data?.result?.data && res.data?.result?.errors?.length === 0) {
                    setTickets(res.data.result.data)
                }
                const resProducts = await clienteAxios.get(`/api/instance/${online24.id}/products`)
                
                if(resProducts.data?.data) {
                    setProducts(resProducts.data.data)
                }

            } catch (error) { console.log(error) }
        }
        if(online24.id && userData && userData.CustomerClientType) {
            consultaApi()
        }
        // eslint-disable-next-line
    }, [online24, userData])

    const handlerTicket = (e) => {
        setTicket({
            ...ticket,
            [e.target.name]: e.target.value
        });
    };

    const onSumitTicket = async (userIndex) => {
        setInProcess(true)
        let localUserData = localStorage.getItem('userData');
        if (localUserData) {
            localUserData = JSON.parse(localUserData);
        } else {
            navigate('/login')
        }
        const ticketReq = {
            USER_ID: userData.UserId,
            MESSAGE: content,
            PRODUCT_ID: ticket.product,
            FILES: files,
            "CLIENT_TYPE": userData.CustomerClientType,
            "CLIENT_ID": userData.CustomerClientId,
            "STATUS_ID": userData.StatusId,
            "SUBSCRIPTION_PLAN_ID": userData.SubscriptionPlanId,
            "CATEGORY_ID": userData.CategoryId,
            "LINE_NAME": userData.LineId,
        }
        try {
            const res = await clienteAxios.post(`/api/instance/${online24.id}/service/24ONLINE/ticket`, ticketReq)
            open()
            if(res.data.result.success === true) {
                navigate(`/dashboard/tickets/${res.data.result.data[0].id}`)
            }
        } catch (error) {
            
        }
        setInProcess(false)
    }

    const reedFiles = async e => {
        try {
            const formData = new FormData()
            formData.append('file', e.target.files[0])
            const res = await clienteAxios.post(`/api/uploadFile/${online24.id}/tickets`, formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            })
            if (res.data) {
                const preView = {type: e.target.files[0].type, url: res.data.fileUrl, name: e.target.files[0].name}
                setPrevFiles([...prevFiles, preView])
                setFiles([...files, res.data.fileUrl])
            }
        } catch (error) {
            console.log(error)
        }
    }

    const deleteFileUrl = (prevFile) => {
        const newFiles = files.filter(item => item !== prevFile.url);
        const newPrevFiles = prevFiles.filter(item => item.url !== prevFile.url);
        setFiles(newFiles)
        setPrevFiles(newPrevFiles)
    }

    const open = async () => {
        if(hiddenTicket) {
            setHiddenTicket(!hiddenTicket)
            await new Promise(resolve => setTimeout(resolve, 500));
            setHeightTicket(!heightTicket)
        } else {
            setHeightTicket(!heightTicket)
            await new Promise(resolve => setTimeout(resolve, 1300));
            setHiddenTicket(!hiddenTicket)
        }
 
    }

    const leerFilter = (e) => {
        setFilterData({
            ...filterData,
            [e.target.name]: e.target.value
        })
    }

    const filters = () => {
        setCurrentPage(1)
        if(filterData?.id) {
            const filtered = Object.values(tickets).filter(ticketItem => ticketItem.ID.toString().includes(filterData.id));
            setFilteredTickets(filtered);
        } else if (filterData?.name) {
            const filtered = Object.values(tickets).filter(ticketItem => {
                const nameMatch = filterData.name ? ticketItem.NAME.toLowerCase().includes(filterData.name.toLowerCase()) : true;
                return nameMatch;
            });
            setFilteredTickets(filtered);
        } else {
            setFilteredTickets(Object.values(tickets))
        }
    }

    useEffect(() => {
        filters()
        // eslint-disable-next-line
    }, [tickets]);

    const indexOfLastTicket = currentPage * ticketsPerPage;
    const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;
    const currentTickets = filteredTickets.slice(indexOfFirstTicket, indexOfLastTicket);
    const totalPages = Math.ceil(filteredTickets.length / ticketsPerPage);

    const nextPage = () => {
        if (currentPage < totalPages) {
          setCurrentPage(currentPage + 1)
        }
    };
    
    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    return(
        <>
            
            <div className="dashboard-content" >
            
                <div className="display-flex header-tickets">
                    <h2>
                        <FormattedMessage
                            id='ticketsSupport'
                            defaultMessage='Support Tickets'
                        />:

                        {userData.CustomerClientType === '2' ? ' Company' : ' Contact'} {userData.CustomerName}
                    </h2>
                    {hiddenTicket ? (
                        <button className='boton boton--primario' type='button' onClick={open}>
                            <FormattedMessage
                                id='ticketsOpen'
                                defaultMessage='Open ticket'
                            />
                        </button>
                    ) : null}
                    
                </div>

                <div className="tickets-sidebar">
                    <aside className="aside-tickets">
                        <div className='aside-tickets__filters'>
                            <p>
                                <FormattedMessage
                                    id='ticketsFilters'
                                    defaultMessage='Filters'
                                />:
                            </p>
                            <label htmlFor='id'>
                                <FormattedMessage
                                    id='ticketsFiltersId'
                                    defaultMessage='Ticket Id'
                                />:
                            </label>
                            <input type='number' className='campo' name='id' id='id' onChange={leerFilter}/>

                            <label htmlFor='name'>
                                <FormattedMessage
                                    id='ticketsFiltersTitle'
                                    defaultMessage='Title'
                                />:
                            </label>
                            <input type='text' className='campo' name='name' id='name' onChange={leerFilter}/>

                            {/* <label htmlFor='product'>Product:</label>
                            <select defaultValue='' className='campo' id='product' name='product' >
                                <option value="" disabled>-- Select a Product --</option>
                            </select> */}

                            <label>
                                <FormattedMessage
                                    id='ticketsFiltersStatus'
                                    defaultMessage='Status'
                                />:
                            </label>
                            <select defaultValue='1' className='campo' id='status' name='status' onChange={leerFilter} >
                                <option value="1" >
                                    <FormattedMessage
                                        id='ticketsFiltersStatusAll'
                                        defaultMessage='All'
                                    />
                                </option>
                                <option value="2" >
                                    <FormattedMessage
                                        id='ticketsFiltersStatusOpen'
                                        defaultMessage='Open'
                                    />
                                </option>
                                <option value="3" >
                                    <FormattedMessage
                                        id='ticketsFiltersStatusClose'
                                        defaultMessage='Close'
                                    />
                                </option>
                                <option value="4" >
                                    <FormattedMessage
                                        id='ticketsFiltersStatusProgress'
                                        defaultMessage='In progress'
                                    />
                                </option>
                            </select>

                            <label className='mt-3' >
                                <FormattedMessage
                                    id='ticketsFiltersDate'
                                    defaultMessage='Date'
                                />:
                            </label>
                            <div className='date_filter' >

                                <div className='display-flex gap-3 align-items-center' >
                                    <label htmlFor='start'>
                                        <FormattedMessage
                                            id='ticketsFiltersFrom'
                                            defaultMessage='From'
                                        />:
                                    </label>
                                    <input type='date' className='campo' name='start' id='start' onChange={leerFilter} max={fechaHoy}/>
                                </div>

                                <div className='display-flex gap-3 align-items-center' >
                                    <label htmlFor='end'>
                                        <FormattedMessage
                                            id='ticketsFiltersTo'
                                            defaultMessage='To'
                                        />:
                                    </label>
                                    <input type='date' className='campo' name='end' id='end' onChange={leerFilter}/>
                                </div>
                            </div>

                            <button className='boton boton--primario' type='button' onClick={filters}>
                                <FormattedMessage
                                    id='ticketsFilter'
                                    defaultMessage='Filter'
                                />
                            </button>
                        </div>
                    </aside>

                    <article className="tickets-bar">
                        {currentTickets.map((ticketItem, i) => (
                            <div key={i} className='ticket display-flex'>
                                <FontAwesomeIcon icon={faComments} />
                                <p className='no-margin'>#{ticketItem.ID}</p>
                                <Link to={`/dashboard/tickets/${ticketItem.ID}`}>{ticketItem.NAME}</Link>
                            </div>
                        ))}

                        {!hiddenTicket ? (
                            <div className={`card-custom accordion ${heightTicket ? 'active': null}`} >

                                
                                <h2 className="no-padding no-margin" >
                                    <FormattedMessage
                                        id='ticketsOpenNew'
                                        defaultMessage='Open a new ticket and send your problem'
                                    />
                                </h2>
            
                                <i className="bi bi-arrow-down-circle" ></i>
            
                                <form className='accordion__content' >
                                    <div className="open-ticket__producto">
                                        <img
                                            src='/img/img4.svg'
                                            alt="answer"
                                        />
            
                                        <div className='open-ticket__inputs'>
                                            {/* <input type='text' placeholder='Title...' className='campo' name='title' /> */}
            
                                            {/* <label for="product" className='custom-select'>Product: */}
                                            <select value={ticket.product} className='campo' id='product' name='product' onChange={handlerTicket}>
                                                <option value="" disabled>
                                                    <FormattedMessage
                                                        id='ticketsSelectProduct'
                                                        defaultMessage='-- Select a Product --'
                                                    />
                                                </option>
                                                {products.map(product => (
                                                    <option key={product.id} value={product.id}>{product.name}</option>
                                                ))}
                                            </select>
                                            {/* </label> */}
            
                                            <div className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    name="agree"
                                                    id="agree"
                                                />
                                                <label for='agree' className='no-margin'>
                                                    
                                                    <FormattedMessage
                                                        id='ticketsIAgree'
                                                        defaultMessage='I agree to the processing of personal data'
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='jodit-ticket'>
                                        <textarea
                                            rows={8}
                                            className="campo textarea-full"
                                            name="question"
                                            placeholder="Write your question"
                                            onChange={(e) => setContent(e.target.value)}
                                        >
                                        </textarea>
                                    </div>

                                    {inProcess ? (
                                        <div className="content-loader-login">
                                            <span className="loader-login"></span>
                                        </div>
                                    ) : (
                                        <button className='boton boton--primario' type='button' onClick={() => onSumitTicket()} >
                                            <FormattedMessage
                                                id='ticketsOpen'
                                                defaultMessage='Open ticket'
                                            />
                                        </button>
                                    )}

                                    <button className='boton boton--primario boton--file' type='button' >
                                        <input
                                            className='hidden'
                                            type="file"
                                            name="files"
                                            onChange={reedFiles}
                                        />
                                        
                                        <FormattedMessage
                                            id='uploadFiles'
                                            defaultMessage='Upload files'
                                        /> <FontAwesomeIcon icon={faCloudArrowUp} />
                                    </button>
                                    
                                    <div className='preview-files'>
                                        {prevFiles.map((prevFile, i) => {
                                            // eslint-disable-next-line
                                            
                                            if(prevFile.type == 'image/gif' || prevFile.type == 'image/vnd.ms-photo' || prevFile.type == 'image/jpe' || prevFile.type == 'image/jpg' || prevFile.type == 'image/png' || prevFile.type == 'image/svg+xml' || prevFile.type == 'image/jpeg') {
                                                return (
                                                    <div key={i} className='preview-file' onClick={() => deleteFileUrl(prevFile)}>
                                                        <img src={prevFile.url} alt='pre'/>
                                                        
                                                        <FontAwesomeIcon className="delete-file" icon={faTrash} />
                                                    </div>
                                                )
                                                // eslint-disable-next-line
                                            } else if(prevFile.type == 'application/pdf') {
                                                return (
                                                    <div key={i} className='preview-file' onClick={() => deleteFileUrl(prevFile)}>
                                                        <FontAwesomeIcon icon={faFilePdf} />
                                                        
                                                        <FontAwesomeIcon className="delete-file" icon={faTrash} />
                                                    </div>
                                                )
                                            } else if(!prevFile.type) {
                                                return null
                                            } else {
                                                return (
                                                    <div key={i} className='preview-file' onClick={() => deleteFileUrl(prevFile)}>
                                                        <FontAwesomeIcon icon={faFile} />
                                                        
                                                        <FontAwesomeIcon className="delete-file" icon={faTrash} />
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </form>
                            </div>
                        ) : null}
                        {/* Paginación */}

                        <div className="display-flex separador mt-5">
                            <nav className="paginador display-flex">
                                <div className="paginador__back">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="12"
                                        height="8"
                                        viewBox="0 0 12 8"
                                        fill="none">
                                        <path
                                            d="M0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659728 4.53553 0.464465C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM12 3.5L1 3.5L1 4.5L12 4.5L12 3.5Z"
                                            fill="#2F7CDA"
                                        />
                                    </svg>

                                    <button onClick={prevPage} disabled={currentPage === 1}>
                                        <FormattedMessage
                                            id='back'
                                            defaultMessage='Back'
                                        />
                                    </button>
                                </div>
                                {Array.from({ length: totalPages }, (_, index) => (
                            
                                    <button key={index} className={`paginador__pages ${currentPage === index + 1 ? "active" : ""}`} onClick={() => paginate(index + 1)}>{index + 1}</button>
                                
                                ))}

                                <div className="paginador__next">
                                    <button onClick={nextPage} disabled={currentPage === totalPages}>
                                        <FormattedMessage
                                            id='next'
                                            defaultMessage='Next'
                                        />
                                    </button>

                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="12"
                                        height="8"
                                        viewBox="0 0 12 8"
                                        fill="none">
                                        <path
                                            d="M11.3536 3.64645C11.5488 3.84171 11.5488 4.15829 11.3536 4.35355L8.17157 7.53553C7.97631 7.7308 7.65973 7.7308 7.46447 7.53553C7.2692 7.34027 7.2692 7.02369 7.46447 6.82843L10.2929 4L7.46447 1.17157C7.2692 0.97631 7.2692 0.659728 7.46447 0.464465C7.65973 0.269203 7.97631 0.269203 8.17157 0.464465L11.3536 3.64645ZM-4.37121e-08 3.5L11 3.5L11 4.5L4.37121e-08 4.5L-4.37121e-08 3.5Z"
                                            fill="#2F7CDA"
                                        />
                                    </svg>
                                </div>

                            </nav>
                        </div>
                        
                    </article>
                </div>

            </div>
            

        </>
    )
}
export default Tickets