import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

function Sidebar() {
    const navigate = useNavigate()
    const logOut = () => {
        localStorage.removeItem('userData');
    }

    let localUserData = localStorage.getItem('userData');
    if (localUserData) {
        localUserData = JSON.parse(localUserData);
    } else {
        const currentURL = window.location.pathname;
        localStorage.setItem('prevUrl', currentURL)
        navigate('/login')
    }
    
    return(
        <nav className='dboard-nav no-margin no-padding'>
            {/* <div>
                <Link to={`#`} className="dboard-nav-logo no-margin no-padding" >
                    <img src="/img/logo1.png" alt="logo" />
                    <span className='dboard-nav-item'>Brand</span>
                </Link>
            </div> */}
            {/* <div>
                <Link to={`/dashboard`} className="dboard-nav-a" >
                    <i className="bi bi-house"></i>
                    <span className='dboard-nav-item'>Home</span>
                </Link>
            </div> */}
            <div>
                <Link to={ localUserData && localUserData.length === 1 ? `/dashboard/tickets-management/${localUserData[0].CustomerClientId}` : '/dashboard/tickets'} className="dboard-nav-a" >
                    <i className="bi bi-ticket"></i>
                    <span className='dboard-nav-item'>
                        <FormattedMessage
                            id='ticketsSupport'
                            defaultMessage='Tickets'
                        />
                    </span>
                </Link>
            </div>
            {/* <div>
                <Link to={`#`} className="dboard-nav-a" >
                    <i className="bi bi-gear-fill"></i>
                    <span className='dboard-nav-item'>Settings</span>
                </Link>
            </div>
            <div>
                <Link to={`#`} className="dboard-nav-a" >
                    <i className="bi bi-person-square"></i>
                    <span className='dboard-nav-item'>Profile</span>
                </Link>
            </div> */}
            <div>
                <Link to={`/`} className="dboard-nav-a logout" onClick={logOut} >
                    <i className="bi bi-box-arrow-left"></i>
                    <span className='dboard-nav-item'>
                        <FormattedMessage
                            id='sidebarLogOut'
                            defaultMessage='Log out'
                        />
                    </span>
                </Link>
            </div>
        </nav>
    )
}
export default Sidebar