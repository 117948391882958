import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { PageContext } from '../../../context/pageContext'
import { langContext } from '../../../context/langContext';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Header() {
// eslint-disable-next-line
    const [ _online24, _setOnline24, page, _setPage ] = useContext(PageContext)
    const lang = useContext(langContext)
    let userData = localStorage.getItem('userData');

    return(
        <header className="header">

            <div className="barra display-flex">

                <div className="barra__izquierda">

                    <div className='flex-column-center max-w-13'>   

                        <Link className="logo" to={'/'}>
                            {page.logo? (
                                <img className="" src={page.logo} alt="logo" />
                            ) :  null}
                        </Link>

                    </div>

                    
                    {/* <nav className={`navegacion ${menu ? 'active' : ''}`}>
                        <a href="#" className="navegacion__enlace navegacion__enlace--primario">General</a>
                        <p className='no-margin'>•</p>
                        <a href="#" className="navegacion__enlace navegacion__enlace--primario">Documents</a>
                        <p className='no-margin'>•</p>
                        <a href="#" className="navegacion__enlace navegacion__enlace--primario">Settings</a>
                        <p className='no-margin'>•</p>
                        <a href="#" className="navegacion__enlace navegacion__enlace--primario">Support</a>
                        <p className='no-margin'>•</p>
                        <a href="#" className="navegacion__enlace navegacion__enlace--primario">Billing and Payments</a>
                        <p className='no-margin'>•</p>
                        <a href="#" className="navegacion__enlace navegacion__enlace--primario">Other</a>
                    </nav> */}

                </div>

                <div className="barra__derecha margin_left">
                    {/* <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.2163 15.3821C18.4442 13.7724 19.186 11.7538 19.186 9.58188C19.186 4.29268 14.8884 0 9.59302 0C4.29767 0 0 4.29268 0 9.58188C0 14.8711 4.29767 19.1638 9.59302 19.1638C11.7674 19.1638 13.7884 18.4228 15.4 17.1963L19.8256 21.6167C20.0814 21.8722 20.414 22 20.7209 22C21.0279 22 21.386 21.8722 21.6163 21.6167C22.1279 21.1057 22.1279 20.3136 21.6163 19.8026L17.2163 15.3821ZM2.55814 9.58188C2.55814 5.69803 5.70465 2.55517 9.59302 2.55517C13.4814 2.55517 16.6279 5.69803 16.6279 9.58188C16.6279 13.4657 13.4558 16.6086 9.59302 16.6086C5.73023 16.6086 2.55814 13.4402 2.55814 9.58188Z" fill="#2F6FDA"/>
                    </svg>


                    <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M3.57584 8.42702C3.57584 5.30902 5.64148 2.44383 8.65388 1.43259C9.17029 0.505621 10.117 0 11.1499 0C12.1827 0 13.1294 0.505621 13.6459 1.43259C16.6583 2.44383 18.7239 5.30902 18.7239 8.42702C18.7239 11.1237 19.4985 13.736 21.0478 16.0113L21.9945 17.4439C22.3388 18.0338 22.4248 18.708 22.0806 19.2979C21.8224 19.8878 21.1338 20.2248 20.4453 20.2248H15.8789C15.2446 22.1086 13.5626 23.5115 11.4976 23.5115C9.43267 23.5115 7.75064 22.1086 7.11638 20.2248H1.85447C1.16592 20.2248 0.563439 19.8878 0.219165 19.2979C-0.125109 18.708 -0.0390408 18.0338 0.305233 17.4439L1.25199 16.0113C2.80122 13.736 3.57584 11.1237 3.57584 8.42702ZM16.7239 8.42702C16.7239 11.5096 17.6106 14.5118 19.3865 17.1251L20.1133 18.2248H2.18644L2.91319 17.1251C4.68911 14.5118 5.57584 11.5096 5.57584 8.42702C5.57584 6.17994 7.08152 4.07011 9.29036 3.32861L10.0243 3.08224L10.4011 2.40593C10.537 2.16195 10.7844 2 11.1499 2C11.5153 2 11.7628 2.16195 11.8987 2.40593L12.2755 3.08224L13.0094 3.32861C15.2182 4.07011 16.7239 6.17994 16.7239 8.42702ZM11.4976 21.5115C10.6861 21.5115 9.92564 21.097 9.42775 20.4062H13.5675C13.0696 21.097 12.3091 21.5115 11.4976 21.5115Z" fill="#29235C"/>
                    </svg> */}

                    
                    


                    <div className='display-flex dropdown-activator lang-dropdown jus justify-content-center'>
                        <button className='m-0 lang-dropdown__buttom'><i className="bi bi-translate"></i></button>

                        <ul className='dropdown no-margin no-padding'>
                            <li onClick={() => lang.setLenguaje('en-US')} ><Link className="dropdown-a lang" to={`#`}><img src='/img/united-states.png'/></Link></li>
                            <li onClick={() => lang.setLenguaje('it-IT')} ><Link className="dropdown-a lang" to={`#`}><img src='/img/italy.png'/></Link></li>
                            <li onClick={() => lang.setLenguaje('es-ES')} ><Link className="dropdown-a lang" to={`#`}><img src='/img/spain.png'/> </Link></li>
                        </ul>

                    </div>

                    <Link to={userData ? `/dashboard/tickets` : `/login`} className="login-a" >
                        
                        <div className='btn-usser display-flex'>

                            <div className='btn-usser__svg display-flex flex-center'>
                                <svg className='centrar-texto' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="user 1" opacity="0.7" clipPath="url(#clip0_2_1795)">
                                    <path id="Vector" d="M10.4724 8.47179C11.1259 7.95756 11.603 7.2524 11.8372 6.45442C12.0713 5.65643 12.051 4.8053 11.7789 4.01943C11.5068 3.23357 10.9965 2.55205 10.3191 2.06969C9.64161 1.58733 8.83066 1.32813 7.99902 1.32812C7.16739 1.32812 6.35643 1.58733 5.67897 2.06969C5.00152 2.55205 4.49125 3.23357 4.21916 4.01943C3.94707 4.8053 3.9267 5.65643 4.16086 6.45442C4.39503 7.2524 4.87209 7.95756 5.52569 8.47179C4.40574 8.92048 3.42855 9.66469 2.69828 10.6251C1.96802 11.5854 1.51206 12.726 1.37902 13.9251C1.36939 14.0127 1.3771 14.1013 1.4017 14.1858C1.42631 14.2704 1.46733 14.3493 1.52243 14.418C1.6337 14.5568 1.79554 14.6457 1.97235 14.6651C2.14917 14.6846 2.32646 14.633 2.46524 14.5217C2.60401 14.4104 2.69291 14.2486 2.71235 14.0718C2.85874 12.7686 3.48015 11.565 4.45783 10.691C5.43552 9.81697 6.70095 9.33381 8.01235 9.33381C9.32376 9.33381 10.5892 9.81697 11.5669 10.691C12.5446 11.565 13.166 12.7686 13.3124 14.0718C13.3305 14.2356 13.4086 14.3869 13.5318 14.4965C13.6549 14.606 13.8142 14.6661 13.979 14.6651H14.0524C14.2271 14.645 14.3868 14.5567 14.4967 14.4193C14.6066 14.2819 14.6578 14.1067 14.639 13.9318C14.5053 12.7293 14.0469 11.5858 13.3129 10.6239C12.5789 9.66207 11.597 8.91811 10.4724 8.47179ZM7.99902 7.99846C7.4716 7.99846 6.95603 7.84206 6.5175 7.54904C6.07897 7.25602 5.73718 6.83955 5.53534 6.35228C5.33351 5.86501 5.2807 5.32883 5.38359 4.81155C5.48649 4.29427 5.74046 3.81911 6.1134 3.44617C6.48634 3.07323 6.9615 2.81926 7.47878 2.71636C7.99606 2.61347 8.53224 2.66628 9.01951 2.86811C9.50678 3.06994 9.92326 3.41174 10.2163 3.85027C10.5093 4.2888 10.6657 4.80437 10.6657 5.33179C10.6657 6.03903 10.3847 6.71731 9.88464 7.21741C9.38454 7.7175 8.70626 7.99846 7.99902 7.99846Z" fill="#706C92" stroke="#706C92"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_2_1795">
                                    <rect width="16" height="16" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                            </div>

                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M16.8998 9.19844C16.4998 8.79844 15.8998 8.79844 15.4998 9.19844L11.9998 12.6984L8.4998 9.19844C8.0998 8.79844 7.4998 8.79844 7.0998 9.19844C6.6998 9.59844 6.6998 10.1984 7.0998 10.5984L11.2998 14.7984C11.4998 14.9984 11.6998 15.0984 11.9998 15.0984C12.2998 15.0984 12.4998 14.9984 12.6998 14.7984L16.8998 10.5984C17.2998 10.1984 17.2998 9.59844 16.8998 9.19844Z" fill="#706C92"/>
                                </svg>
                            </div>

                        </div>
                    </Link>

                </div>
            </div>

        </header>

    )
}

export default Header