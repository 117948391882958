import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PageContext } from '../../../context/pageContext';
import { langContext } from '../../../context/langContext';
import clienteAxios from '../../../config/axios';

function Request() {
    const [ online24 ] = useContext(PageContext)
    const { lang } = useContext(langContext)
    const [searchTerm, setSearchTerm] = useState('');
    const [products, setProducts] = useState([]);
    const navigate = useNavigate()
    
    useEffect(() => {
        //Get a: /api/products
        const consultaApi = async () => {
          const res = await clienteAxios.get(`/api/instance/${online24.id}/products`)
          if(res.data?.data) {
            setProducts(res.data.data)
          }
        }
        consultaApi()
        // eslint-disable-next-line
    }, []);

    const handleSearch = (e) => {
        e.preventDefault();
        // Redirecciona al componente de búsqueda y pasa el término de búsqueda como parámetro
        navigate(`/searching?term=${encodeURIComponent(searchTerm)}`);
    };

    const handleSearchButtom = (e) => {
        navigate(`/searching?term=${encodeURIComponent(e.target.textContent)}`);
    };

    return(
        <div className="request separador display-flex flex-center">
            <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
            </ul>

            <div className="contenedor ">
                <div className="search-bar display-flex flex-center">

                    <img src="/img/robot.png" alt="SVG" className="search-img" />

                    <form className='position-r' onSubmit={handleSearch}>
                        <input 
                            type="text" 
                            className="search-input search-icon input-container animated" 
                            placeholder={lang.requestMake ? lang.requestMake : "Make a request"} 
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        
                        <div className="button-group">
                            {products.slice(0, 5).map(product => (
                                // eslint-disable-next-line
                                <a className='button-group__a transUp' key={product.id} onClick={handleSearchButtom}>{product.name}</a>
                            ))}
                        </div>

                    </form>

                </div>

            </div>
        </div>
    )
}

export default Request