import React, { useContext, useState } from "react";
import clienteAxios from "../../../config/axios.js";
import { useEffect } from "react";
import Category from "./Category.js";
import { useNavigate } from "react-router-dom";
import { PageContext } from "../../../context/pageContext.js";

function OpenCategory() {
  const [ online24 ] = useContext(PageContext)
  const [categorias, setCategorias] = useState([]);
  const [subCategorias, setSubCategoria] = useState([]);
  const [subSubCategorias, setSubSubcategoria] = useState([]);
  const [products, setProducts] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const navigate = useNavigate()

  const handleSearchButtom = (e) => {
    navigate(`/searching?term=${encodeURIComponent(e)}`);
  };

  useEffect(() => {
    //Get a: /api/products
    const consultaApi = async () => {
      const res = await clienteAxios.get(`/api/instance/${online24.id}/products`)
      if(res.data?.data) {
        setProducts(res.data.data)
      }
    }
    consultaApi()
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
      setSubCategoria([])
      setSubSubcategoria([])
  }, [categorias]);

  const handleClick = (categoriaId, categoriaName) => {
      // Busca la categoría correspondiente
      const categoriaClicked = categorias.find((categoria) => categoria.id === categoriaId);

      // Si la categoría existe, guarda sus hijos en el state de subCategorias
      if (categoriaClicked) {
        setSubCategoria(categoriaClicked.childs || []);
        if(!categoriaClicked?.childs || categoriaClicked?.childs?.length === 0) {
          handleSearchButtom(categoriaName)
        }
      } else {
        // Si la categoría no existe, manejar errores
        console.log(`No se encontró la categoría con ID ${categoriaId}`);
      }

      setSubSubcategoria([])
  };

  const handleClick2 = (subCategoriaId, categoriaName) => {
      // Busca la subCategoría correspondiente
      const categoriaClicked = subCategorias.find((subCategoria) => subCategoria.id === subCategoriaId);

      // Si la subCategoría existe, guarda sus hijos en el state de subSubCategorias
      if (categoriaClicked) {
        setSubSubcategoria(categoriaClicked.childs || []);
        if(!categoriaClicked?.childs || categoriaClicked?.childs?.length === 0) {
          handleSearchButtom(categoriaName)
        }
      } else {
        // Si la subCategoría no existe, manejar errores
        console.log(`No se encontró la categoría con ID ${subCategoriaId}`);
      }
  };

  return (
    <div className=" aside">

      <aside className="example">
        
        <ul className="lista no-padding no-margin">
          {products.map((product, i) => (
            <Category
              key={product.id}
              product={product}
              i={i}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              setCategorias={setCategorias}
              online24={online24}
            />
          ))}
        </ul>

        <div className={`open display-flex ${activeIndex !== null ? "active" : ""}`}>

          <div className={`open1 ${activeIndex !== null ? "active" : ""}`}>
            <button type='button' className='boton boton--secundario' onClick={() => setActiveIndex(null)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
                <path d="M0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659728 4.53553 0.464465C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM13 3.5L1 3.5L1 4.5L13 4.5L13 3.5Z" fill="white"/>
                </svg>
                Back
            </button>
            
            {/* <div className="open1__buttons display-flex">
              <button className="boton boton--naranja" type="button">
                Operational assistance
              </button>
              <button className="boton boton--naranja" type="button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none">
                  <path
                    d="M14.0827 12.5918C15.0867 11.2741 15.6933 9.62164 15.6933 7.84373C15.6933 3.51399 12.1793 0 7.84959 0C3.51985 0 0.00585938 3.51399 0.00585938 7.84373C0.00585938 12.1735 3.51985 15.6875 7.84959 15.6875C9.6275 15.6875 11.2799 15.0809 12.5977 14.0769L16.2162 17.6954C16.4254 17.9046 16.6973 18.0092 16.9483 18.0092C17.1993 18.0092 17.4921 17.9046 17.6804 17.6954C18.0987 17.2771 18.0987 16.6287 17.6804 16.2104L14.0827 12.5918ZM2.09752 7.84373C2.09752 4.6644 4.67026 2.09166 7.84959 2.09166C11.0289 2.09166 13.6017 4.6644 13.6017 7.84373C13.6017 11.0231 11.008 13.5958 7.84959 13.5958C4.69118 13.5958 2.09752 11.0021 2.09752 7.84373Z"
                    fill="#29235C"
                  />
                </svg>
              </button>
            </div> */}

            <div className="open1__categories">
              {categorias.map((categoria) => (
                <div
                  className="open1-category display-flex display-flex"
                  key={categoria.id}
                  onClick={() => handleClick(categoria.id, categoria.name)}>
                  <p>{categoria.name}</p>
                  {categoria?.childs && categoria?.childs?.length !== 0  ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none">
                      <circle cx="10" cy="10" r="10" fill="#2F7CDA" />
                      <path
                        d="M12.3536 10.3536C12.5488 10.1583 12.5488 9.84171 12.3536 9.64645L9.17157 6.46447C8.97631 6.2692 8.65973 6.2692 8.46447 6.46447C8.2692 6.65973 8.2692 6.97631 8.46447 7.17157L11.2929 10L8.46447 12.8284C8.2692 13.0237 8.2692 13.3403 8.46447 13.5355C8.65973 13.7308 8.97631 13.7308 9.17157 13.5355L12.3536 10.3536ZM11 10.5H12V9.5H11V10.5Z"
                        fill="white"
                      />
                    </svg>
                  ) : null}
                </div>
              ))}
            </div>
          </div>

          <div className={`open2 ${subCategorias.length !== 0 ? "active" : ""}`}>

            <button type='button' className='boton boton--secundario' onClick={() => setSubCategoria([])}>
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
                <path d="M0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659728 4.53553 0.464465C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM13 3.5L1 3.5L1 4.5L13 4.5L13 3.5Z" fill="white"/>
                </svg>
                Back
            </button>

            {subCategorias.map((subCategoria) => (
                <div
                  className="open2-category display-flex"
                  key={subCategoria.id}
                  onClick={() => handleClick2(subCategoria.id, subCategoria.name)}>
                  <p>{subCategoria.name}</p>
                  {subCategoria?.childs && subCategoria?.childs?.length !== 0  ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none">
                      <circle cx="10" cy="10" r="10" fill="#2F7CDA" />
                      <path
                        d="M12.3536 10.3536C12.5488 10.1583 12.5488 9.84171 12.3536 9.64645L9.17157 6.46447C8.97631 6.2692 8.65973 6.2692 8.46447 6.46447C8.2692 6.65973 8.2692 6.97631 8.46447 7.17157L11.2929 10L8.46447 12.8284C8.2692 13.0237 8.2692 13.3403 8.46447 13.5355C8.65973 13.7308 8.97631 13.7308 9.17157 13.5355L12.3536 10.3536ZM11 10.5H12V9.5H11V10.5Z"
                        fill="white"
                      />
                    </svg>
                  ) : null}
              </div>
            ))}
          </div>

          <div className={`open3 ${subSubCategorias.length !== 0 ? "active" : ""}`}>

            <button type='button' className='boton boton--secundario' onClick={() => setSubSubcategoria([])}>
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
                <path d="M0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659728 4.53553 0.464465C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM13 3.5L1 3.5L1 4.5L13 4.5L13 3.5Z" fill="white"/>
                </svg>
                Back
            </button>

            {subSubCategorias.map((subSubcategoria) => (
              <p className="open3__result" key={subSubcategoria.id} onClick={e => handleSearchButtom(e.target.textContent)}>
                {subSubcategoria.name}
              </p>
            ))}
          </div>

        </div>

      </aside>



    </div>
  );
}

export default OpenCategory;
