import React from 'react'
import { Fragment } from 'react'
import Chat from './Chat'
import ChatHero from './ChatHero'
import Header from '../layout/Header'

function ChatBot() {
    return(
        <Fragment>
            <Header />
            <ChatHero/>
            <Chat/>
        </Fragment>
    )
}
export default ChatBot