import React from 'react'

function ChatHero() {
    return(
        <div className='chat-hero display-flex flex-center'>

            <di className='chat-hero__izquierdo display-flex'>
                <img src='/img/ChatHero.svg' alt='chathero' />
                <div>
                    <h3 className='no-margin'>Bob</h3>
                    <p className='no-margin'>search bot</p>
                </div>
            </di>

            <div className='chat-hero__derecho display-flex'>
                <button type='button' className='boton'>Last search query</button>

                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="7" viewBox="0 0 34 7" fill="none">
                    <path d="M16.5375 0.043457C14.8838 0.043457 13.5307 1.39443 13.5307 3.04562C13.5307 4.69681 14.8838 6.04778 16.5375 6.04778C18.1912 6.04778 19.5443 4.69681 19.5443 3.04562C19.5443 1.39443 18.1912 0.043457 16.5375 0.043457ZM3.00682 0.043457C1.35307 0.043457 0 1.39443 0 3.04562C0 4.69681 1.35307 6.04778 3.00682 6.04778C4.66057 6.04778 6.01364 4.69681 6.01364 3.04562C6.01364 1.39443 4.66057 0.043457 3.00682 0.043457ZM30.0682 0.043457C28.4144 0.043457 27.0614 1.39443 27.0614 3.04562C27.0614 4.69681 28.4144 6.04778 30.0682 6.04778C31.7219 6.04778 33.075 4.69681 33.075 3.04562C33.075 1.39443 31.7219 0.043457 30.0682 0.043457Z" fill="white"/>
                </svg>

                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.7823 1L1.0752 1.70711L7.79289 8.4248L1.07556 15.1421L1.78267 15.8492L8.5 9.13191L15.2173 15.8492L15.9244 15.1421L9.20711 8.4248L15.9248 1.70711L15.2177 1L8.5 7.7177L1.7823 1Z" fill="white"/>
                    <path d="M1.0752 1.70711L0.721642 1.35355L0.368089 1.70711L0.721642 2.06066L1.0752 1.70711ZM1.7823 1L2.13586 0.646447L1.7823 0.292893L1.42875 0.646447L1.7823 1ZM7.79289 8.4248L8.14645 8.77836L8.5 8.4248L8.14645 8.07125L7.79289 8.4248ZM1.07556 15.1421L0.722009 14.7886L0.368456 15.1421L0.722009 15.4957L1.07556 15.1421ZM1.78267 15.8492L1.42912 16.2028L1.78267 16.5563L2.13622 16.2028L1.78267 15.8492ZM8.5 9.13191L8.85355 8.77836L8.5 8.4248L8.14645 8.77836L8.5 9.13191ZM15.2173 15.8492L14.8638 16.2028L15.2173 16.5563L15.5709 16.2028L15.2173 15.8492ZM15.9244 15.1421L16.278 15.4957L16.6315 15.1421L16.278 14.7886L15.9244 15.1421ZM9.20711 8.4248L8.85355 8.07125L8.5 8.4248L8.85355 8.77836L9.20711 8.4248ZM15.9248 1.70711L16.2784 2.06066L16.6319 1.70711L16.2784 1.35355L15.9248 1.70711ZM15.2177 1L15.5713 0.646447L15.2177 0.292893L14.8641 0.646447L15.2177 1ZM8.5 7.7177L8.14645 8.07125L8.5 8.42481L8.85355 8.07125L8.5 7.7177ZM1.42875 2.06066L2.13586 1.35355L1.42875 0.646447L0.721642 1.35355L1.42875 2.06066ZM8.14645 8.07125L1.42875 1.35355L0.721642 2.06066L7.43934 8.77836L8.14645 8.07125ZM7.43934 8.07125L0.722009 14.7886L1.42912 15.4957L8.14645 8.77836L7.43934 8.07125ZM0.722009 15.4957L1.42912 16.2028L2.13622 15.4957L1.42912 14.7886L0.722009 15.4957ZM2.13622 16.2028L8.85355 9.48547L8.14645 8.77836L1.42912 15.4957L2.13622 16.2028ZM15.5709 15.4957L8.85355 8.77836L8.14645 9.48547L14.8638 16.2028L15.5709 15.4957ZM15.5709 14.7886L14.8638 15.4957L15.5709 16.2028L16.278 15.4957L15.5709 14.7886ZM8.85355 8.77836L15.5709 15.4957L16.278 14.7886L9.56066 8.07125L8.85355 8.77836ZM9.56066 8.77836L16.2784 2.06066L15.5713 1.35355L8.85355 8.07125L9.56066 8.77836ZM16.2784 1.35355L15.5713 0.646447L14.8641 1.35355L15.5713 2.06066L16.2784 1.35355ZM14.8641 0.646447L8.14645 7.36414L8.85355 8.07125L15.5713 1.35355L14.8641 0.646447ZM1.42875 1.35355L8.14645 8.07125L8.85355 7.36414L2.13586 0.646447L1.42875 1.35355Z" fill="white"/>
                </svg>

            </div>

        </div>
    )
}

export default ChatHero