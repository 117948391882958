import React from 'react'
import { Link } from 'react-router-dom';

function Settings({ html, headingRefs }) {

    // Función para agregar referencias a las etiquetas de encabezado
    const handleRef = (level, element) => {
        console.log(level, element)
      headingRefs.current[level] = headingRefs.current[level] || [];
      headingRefs.current[level].push(element);
    };
  
    // Función para recorrer y añadir referencias a las etiquetas de encabezado
    // eslint-disable-next-line
    const traverseAndAddRefs = (node) => {
      if (node?.nodeType === Node.ELEMENT_NODE) {
        const tagName = node.tagName.toLowerCase();
        if (tagName.startsWith('h')) {
          const level = Object.keys(headingRefs.current) + 1;
          handleRef(level, node);
        }
  
        // Recorre los hijos del nodo
        node.childNodes.forEach((childNode) => {
          traverseAndAddRefs(childNode);
        });
      }
    };
  
    // Función para manejar el clic en el índice y desplazarse a la sección correspondiente
    const scrollToSection = (level) => {
      const elements = headingRefs.current[level];
      if (elements && elements.length > 0) {
        elements[0].scrollIntoView({ behavior: 'smooth' });
      }
    };
  
    return(
        <div className='settings separador'>
            <h3>Sections of the article</h3>

            <ul className='general-settings__list no-padding no-margin'>
                {Object.keys(headingRefs.current).map((level) => (
                    <li key={level} onClick={() => scrollToSection(level)} >
                        <p>
                            {headingRefs.current[level][0].textContent || headingRefs.current[level][0].innerText}
                        </p>
                    </li>
                ))}
            </ul>

            <Link type='button' className='boton boton--secundario' to={'/searching'}>
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
                <path d="M0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659728 4.53553 0.464465C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM13 3.5L1 3.5L1 4.5L13 4.5L13 3.5Z" fill="white"/>
                </svg>
                Back
            </Link>

        </div>
    )
}

export default Settings