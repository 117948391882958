import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import clienteAxios from '../config/axios';
const PageContext = React.createContext([ {}, () => {}, {}, () => {}, {}, () => {} ])

const PageProvider = props => {
    const [page, setPage] = useState({
        logo: '',
        company: '',
        pageComponents : []
    })
    const [online24, setOnline24] = useState({
        id: ''
    })
    const [loading, setLoading] = useState(true)
    const [scriptUrl, setScriptUrl] = useState("");
    const navigate = useNavigate()


    useEffect(() => {
        
        const reqApi = async () => {
            try {
                const urlKb = window.location.hostname
                // const urlKb = 'kb.ntc-dev.cc'

                const rest24Online = await clienteAxios.get(`/api/instance/kburl/${urlKb}`)
                
                if(!rest24Online.data.data) {
                    navigate('/erro')
                } if(rest24Online.data?.data?.isActive === false) {
                    navigate('/inactive')
                    
                } else {
                    
                    if(rest24Online.data?.data) {
                        setScriptUrl(rest24Online.data.data.chatScript);
                        if(rest24Online.data?.data.pages.length !== 0) {
                            setPage(rest24Online.data.data.pages[0])
                        }
                        setOnline24(rest24Online.data.data)
                    }
                }

            } catch (error) {
                console.log(error)
                navigate('/erro')
            }
            
            setLoading(false)
        }
        reqApi()
    
        
        // eslint-disable-next-line
    }, []);
  
    useEffect(() => {
        if (scriptUrl) {
            const script = document.createElement("script");
            script.async = true;
            script.src = `${scriptUrl}?${Date.now() / 60000 | 0}`;
    
            const head = document.getElementsByTagName("head")[0];
            head.appendChild(script);
    
            return () => {
                head.removeChild(script);
            };
        }
    }, [scriptUrl]);
  
    return (
        <PageContext.Provider value={[ online24, setOnline24, page, setPage, loading, setLoading ]}>
            {props.children}
        </PageContext.Provider>
    )
}

export {
    PageContext,
    PageProvider
}