import React from 'react'
import { FormattedMessage } from 'react-intl';

function Results404({searchTerm}) {
    return(
        <div className='results-404 flex-column-center separador'>
            <img src='/img/404.svg' alt='404'/>

            <h3>
                <FormattedMessage
                    id='searchArticlesOnRequest'
                    defaultMessage='Articles on request'
                /> 
                 <span className='span-h2--naranja'>{searchTerm}</span>
                 <FormattedMessage
                    id='searchNotFound'
                    defaultMessage='were not found.'
                />
            </h3>
        </div>
    )
}

export default Results404