import React from 'react'
import clienteAxios from '../../../config/axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
library.add(fas);

function Category({product, i, activeIndex, setActiveIndex, setCategorias, online24}) {
    const handleClick = () => {
        if (i === activeIndex) {
            setActiveIndex(null);
            setCategorias([])
        } else {
            setActiveIndex(i);
            const reqApi = async () => {
                const consulta = await clienteAxios.get(`/api/instance/${online24.id}/product/${product.id}/categories`)

                //Get a: /api/instance/${}/product/${product.id}/categories

                 if (consulta.data.data) {
                    // Filtrar categorías con parentId igual a null
                    const filteredCategories = consulta.data.data.filter(category => category.parentId === null || !category.parentId);
                    setCategorias(filteredCategories)
                 } else {
                    setCategorias([])
                 }

            }
    
            reqApi()

        }
        
    }

    return(
        <li className={`${i === activeIndex ? 'active' : ''}`} onClick={() => handleClick()}>
            <FontAwesomeIcon icon={fas[product.icon]}  />

            <p>{product.name}</p>
        </li>
    )
} 

export default Category