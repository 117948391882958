import React, { useContext, useEffect, useState } from 'react'
import { PageContext } from '../../../context/pageContext';
import clienteAxios from '../../../config/axios';

function Content({article}) {
    const [ online24 ] = useContext(PageContext)
    const [relatedPosts, setRelatedPosts] = useState([])


    useEffect(() => {
        const consultaApi = async () => {
            const res = await clienteAxios.get(`/api/instance/${online24.id}/category/${article?.categoryIds[0]}/articles/1/10`)
            if(res.data.data) {
                setRelatedPosts(res.data.data)
            }
        
        }
        if(online24.id && article.categoryIds) {
            consultaApi()
        }
    }, [online24, article]);

    return(
        <div className='contents-content separador'>
            <div className='contents display-flex'>
                <h3>Related Articles</h3>

                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="23" viewBox="0 0 21 23" fill="none">
                    <path d="M14.7002 12.6297L10.3501 16.293L6 12.6297" stroke="#92A0B0" strokeLinejoin="round"/>
                    <path d="M14.7002 7.30157L10.3501 10.9648L6 7.30157" stroke="#D3D6D8" strokeLinejoin="round"/>
                    <rect x="0.5" y="0.5" width="20" height="22" rx="3.5" stroke="#92A0B0"/>
                </svg>
            </div>
            
            <div className='contents-ps'>
                {relatedPosts.map(post => (
                    <a href={post.id !== article.id ? `/article/${post.id}` : null} className={post.id === article.id ? 'active' : null} key={post.id}>{post.name}</a>
                ))}
            </div>
        </div>
    )
}

export default Content