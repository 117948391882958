import React from "react";

function HowToEdit({html, headingRefs}) {

  // Función para agregar referencias a las etiquetas de encabezado
  const handleRef = (level, element) => {
    headingRefs.current[level] = headingRefs.current[level] || [];
    headingRefs.current[level].push(element);
  };

  // Función para recorrer y añadir referencias a las etiquetas de encabezado
  const traverseAndAddRefs = (node) => {
    if (node?.nodeType === Node.ELEMENT_NODE) {
      const tagName = node.tagName.toLowerCase();
      if (tagName.startsWith('h')) {
        const level = Object.keys(headingRefs.current) + 1;
        handleRef(level, node);
      }

      // Recorre los hijos del nodo
      node.childNodes.forEach((childNode) => {
        traverseAndAddRefs(childNode);
      });
    }
  };

  return (
    <div>
      <div className='how-to-edit separador'>
        <h2>{html.name}</h2>
        {html ? (
          <div
              ref={(divElement) => {
                  headingRefs.current = {}; 
                  traverseAndAddRefs(divElement);
              }}
              dangerouslySetInnerHTML={{ __html: html?.content }}
          />
        ) : null}
      </div>

    </div>
  );
}

export default HowToEdit;
