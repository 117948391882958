import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {Fragment} from 'react'
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

function NostreRisorse({ urlVideo, urlImg, h3Content, h2Content }) {

    const vidUrl = `./img/video.mp4`

    return(
        <Fragment>
            <h2 className='centrar-texto'>{h2Content}</h2>

            <div className='group separador'>

                <div className='group__instructions display-flex card-custom'>
                    <img src={urlImg} alt='group' />

                    <div className='group__right'>
                        <h3>{h3Content}</h3>

                        <Link className='boton boton--primario' to={'/searching'}><FontAwesomeIcon icon={faArrowRight} /></Link>
                    </div>
                </div>

                <div className='video'>
                    

                    <ReactPlayer
                        url={urlVideo ? urlVideo : vidUrl}
                        playing={false}
                        volume={0.5}
                        width='100%'
                        height='80%'
                        className = 'react-player'
                        loop={true}
                        controls={true}
                    />
                    
                    {/* <h3>Video about company</h3> */}


                </div>



            </div>


        </Fragment>
    )
}

export default NostreRisorse