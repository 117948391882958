import React, { useContext, useEffect, useState } from 'react'
import clienteAxios from '../../../config/axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { PageContext } from '../../../context/pageContext';
library.add(fas);

function SideMenu() {
  // eslint-disable-next-line
    const [ online24, _setOnline24, _page, _setPage ] = useContext(PageContext)
    const [ menu, guardarMenu ] = useState( false )
    const [products, setProducts] = useState([]);
      useEffect(() => {
        //Get a: /api/products
        const consultaApi = async () => {
          const res = await clienteAxios.get(`/api/instance/${online24.id}/products`)
    
          setProducts(res.data.data)
        }
        if(online24.id) {
          consultaApi()
        }
        
        
      }, [online24]);
    return(
            <div className='sidemenu'>

                <button type='button' className={`menu-h ${menu ? 'active' : ''}`} onClick={() => guardarMenu( !menu )}>
                    <div></div>
                    <div></div>
                    <div></div>
                </button>

                
                <div className={`animation-h ${menu ? 'active' : ''}`}>
                    
                    {products.map(category => (
                        <div className='sidemenu__child' key={category.id}>
                            <FontAwesomeIcon icon={fas[category.icon]}  />
                        </div>
                    ))}
                   
                </div>
        
            </div>
     
    )
}

export default SideMenu