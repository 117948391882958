import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup"
import { PageContext } from '../../context/pageContext';
import clienteAxios from '../../config/axios';
import { FormattedMessage } from 'react-intl';
import { langContext } from '../../context/langContext';

function Register() {
    const navigate = useNavigate()
    const { lang } = useContext(langContext)
    // eslint-disable-next-line
    const [  online24, _setOnline24, page ] = useContext(PageContext)
    const [ inRegister, setInRegister ] = useState(false)
    const [res, setRes] = useState('')
    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
            repeatPasswor: ''
        },
        validationSchema: Yup.object({
        username: Yup.string()
            .required(lang.loginErroEmailReq ? lang.loginErroEmailReq : 'Email is required.')
            .email(lang.loginErroEmail ? lang.loginErroEmail : 'that does not look like an email.'),

        password: Yup.string()
            .required(lang.loginErroPassword ? lang.loginErroPassword : 'Password is required.')
            .matches(
                // eslint-disable-next-line
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*.])(?=.{8,})/,
                lang.registerErroPassword ? lang.registerErroPassword : "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            )
            .oneOf([Yup.ref("repeatPasswor")], lang.registerAreNotSame ? lang.registerAreNotSame : "Passwords are not the same"),

        repeatPasswor: Yup.string()
            .required(lang.registerErroRepeat ? lang.registerErroRepeat : 'Repeat Password is required.')
            .oneOf([Yup.ref("password")], lang.registerAreNotSame ? lang.registerAreNotSame : "Passwords are not the same"),
        }),

        onSubmit: async (formValue) => {
            const { username, password, repeatPasswor } = formValue
            const loginReq = {
                LOGIN: username,
                PASSWORD: password,
                CONFIRM_PASSWORD: repeatPasswor
            }
            setInRegister(true)
            try {
                const register = await clienteAxios.post(`/api/instance/${online24.id}/service/24ONLINE/user`, loginReq)
                console.log(register.data)
                navigate('/login')
            } catch (error) {
                console.log(error);
                setRes(lang.registerYouNotAuth ? lang.registerYouNotAuth : 'You are not authorized to use this site')
            }
            setInRegister(false)
            
            formik.handleReset()
        }
    })


    return(
      <div className="loginKb">
        <div className="login-container">
                <form className="login-form register" onSubmit={formik.handleSubmit}>

                    {page.logo? (
                        <img className="" src={page.logo} alt="logo" />
                    ) :  null}
                    
                    <h2>
                        <FormattedMessage
                            id='register'
                            defaultMessage='Register'
                        />
                    </h2>

                    <div className="form-group">

                        <input 
                            type="text" 
                            id="username" 
                            name="username" 
                            required
                            value={formik.values.username}
                            error={formik.errors.username}
                            onChange={formik.handleChange}
                            className={formik.errors.username ? 'error-login' : null}
                        />
                        
                        <label className='lbl-nomb' htmlFor="username" >
                            <span className='text-nomb'>Email:</span>
                        </label> 
                    </div>

                    <div className="form-group">

                        <input 
                            type="password" 
                            id="password" 
                            name="password" 
                            required
                            value={formik.values.password}
                            error={formik.errors.password}
                            onChange={formik.handleChange}
                            className={formik.errors.password ? 'error-login' : null}
                        />

                        <label className='lbl-nomb' htmlFor="password">
                            <span className='text-nomb'>
                                <FormattedMessage
                                    id='loginPassword'
                                    defaultMessage='Password'
                                />:
                            </span>
                        </label> 
                    </div>

                    <div className="form-group">

                        <input 
                            type="password" 
                            id="repeatPasswor" 
                            name="repeatPasswor" 
                            required
                            value={formik.values.repeatPasswor}
                            error={formik.errors.repeatPasswor}
                            onChange={formik.handleChange}
                            className={formik.errors.repeatPasswor ? 'error-login' : null}
                        />

                        <label className='lbl-nomb' htmlFor="repeatPasswor">
                            <span className='text-nomb'>
                                <FormattedMessage
                                    id='registerRepeatPassword'
                                    defaultMessage='Repeat Password'
                                />:
                            </span>
                        </label> 
                    </div>

                    <p className='error-message'>{formik.errors.username}</p>
                    <p className='error-message'>{formik.errors.password}</p>
                    <p className='error-message'>{formik.errors.repeatPasswor}</p>
                    <p className='error-message'>{res}</p>

                    <div className="checkbox">
                        <input
                            type="checkbox"
                            name="agree"
                            value={true}
                        />
                        <p>
                            <FormattedMessage
                                id='registerAcceptTerms'
                                defaultMessage='Accept terms and conditions'
                            />
                        </p>
                    </div>

                    <div className="group-login-a">
                        <Link to={`/login`} className="login-a" >
                            <FormattedMessage
                                id='loginSignUp'
                                defaultMessage='Sign up'
                            />
                        </Link>
                    </div>

                    {inRegister ? (
                        <div className="content-loader-login">
                            <span className="loader-login"></span>
                        </div>
                    ) : (
                        <button type="submit">
                            <FormattedMessage
                                id='register'
                                defaultMessage='Register'
                            />
                        </button>
                    )}
                </form>
          </div>
      </div>
    )
}
export default Register