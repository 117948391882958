import React, {Fragment} from 'react'

function Questions({item, i, guardarBoton, boton}) {

    const handleClick = i => {
        if (boton === i) {
            return guardarBoton(null)
        }

        guardarBoton(i)
    }

    return (
        <Fragment>

                    <div className={`faq ${boton === i ? 'active' : ''} scrolling`} onClick={() => handleClick(i)}>

                        <div className='question'>
                            <h3 className='no-margin'>{item.question}</h3>

                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10" cy="10" r="10" fill="#F9C815"/>
                                <path d="M9.64645 12.8536C9.84171 13.0488 10.1583 13.0488 10.3536 12.8536L13.5355 9.67157C13.7308 9.47631 13.7308 9.15973 13.5355 8.96447C13.3403 8.7692 13.0237 8.7692 12.8284 8.96447L10 11.7929L7.17157 8.96447C6.97631 8.7692 6.65973 8.7692 6.46447 8.96447C6.2692 9.15973 6.2692 9.47631 6.46447 9.67157L9.64645 12.8536ZM9.5 11.25L9.5 12.5L10.5 12.5L10.5 11.25L9.5 11.25Z" fill="white"/>
                            </svg>

                        </div>

                        <div className='answer'>
                            <p>{item.answer}</p>
                        </div>

                    </div>
    

        </Fragment>
    )
}

export default Questions