import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup"
import { PageContext } from '../../context/pageContext';
import clienteAxios from '../../config/axios';
import { FormattedMessage } from 'react-intl';
import { langContext } from '../../context/langContext';

function Login() {
    // eslint-disable-next-line
    const [ online24, _setOnline24, page ] = useContext(PageContext)
    const [inLoging, setInLoging] = useState(false)
    const navigate = useNavigate()
    const { lang } = useContext(langContext)

    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        validationSchema: Yup.object({
        username: Yup.string()
            .email(lang.loginErroEmail ? lang.loginErroEmail : 'that does not look like an email.')
            .required(lang.loginErroEmailReq ? lang.loginErroEmailReq : 'Username is required.'),

        password: Yup.string()
            .required(lang.loginErroPassword ? lang.loginErroPassword : 'Password is required.'),
        }),

        onSubmit: async (formValue) => {
            const { username, password } = formValue
            const loginReq = {
                LOGIN: username,
                PASSWORD: password
            }
            setInLoging(true)
            try {
                const login = await clienteAxios.post(`/api/instance/${online24.id}/service/24ONLINE/user/auth`, loginReq)
                console.log(login.data)
                if(login.data) {
                    localStorage.setItem('userData', JSON.stringify(login.data.result.data.User))
                    const prevUrl = localStorage.getItem('prevUrl');
                    if(prevUrl) {
                        localStorage.removeItem('prevUrl');
                        navigate(prevUrl)
                    } else {
                        navigate('/dashboard/tickets')
                    }
                    
                }
            } catch (error) {
                console.log(error);
                setRes(lang.loginErroIncorrect ? lang.loginErroIncorrect : 'Username or Password is incorrect')
            }
            setInLoging(false)
            formik.handleReset()
        }
    })

    const [res, setRes] = useState('')

    return(
      <div className="loginKb">
        <div className="login-container">
                <form className="login-form" onSubmit={formik.handleSubmit}>

                    {page.logo? (
                        <img className="" src={page.logo} alt="logo" />
                    ) :  null}
                    
                    <h2>
                        <FormattedMessage
                            id='loginSingIn'
                            defaultMessage='Please sign in'
                        />
                    </h2>

                    <div className="form-group">

                        <input 
                            type="text" 
                            id="username" 
                            name="username" 
                            required
                            value={formik.values.username}
                            error={formik.errors.username}
                            onChange={formik.handleChange}
                            className={formik.errors.username ? 'error-login' : null}
                        />
                        
                        <label className='lbl-nomb' htmlFor="username" >
                            <span className='text-nomb'>
                                <FormattedMessage
                                    id='loginUsername'
                                    defaultMessage='Username'
                                />:
                            </span>
                        </label> 
                    </div>

                    <div className="form-group">

                        <input 
                            type="password" 
                            id="password" 
                            name="password" 
                            required
                            value={formik.values.password}
                            error={formik.errors.password}
                            onChange={formik.handleChange}
                            className={formik.errors.password ? 'error-login' : null}
                        />

                        <label className='lbl-nomb' htmlFor="password">
                            <span className='text-nomb'>
                                <FormattedMessage
                                    id='loginPassword'
                                    defaultMessage='Password'
                                />:
                            </span>
                        </label> 
                    </div>

                    <p className='error-message'>{formik.errors.username}</p>
                    <p className='error-message'>{formik.errors.password}</p>
                    <p className='error-message'>{res}</p>

                    <div className="checkbox">
                        <input
                            type="checkbox"
                            name="agree"
                            value={true}
                        />
                        <p>
                            <FormattedMessage
                                id='loginRemember'
                                defaultMessage='Remember me'
                            />
                        </p>
                    </div>

                    <div className="group-login-a">
                        <Link to={`#`} className="login-a" >
                            <FormattedMessage
                                id='loginForget'
                                defaultMessage='Did you forget your password?'
                            />
                        </Link>

                        <Link to={`/register`} className="login-a" >
                            <FormattedMessage
                                id='loginSignUp'
                                defaultMessage='Sign up'
                            />
                        </Link>
                    </div>

                    {inLoging ? (
                        <div className="content-loader-login">
                            <span className="loader-login"></span>
                        </div>
                    ) : (
                        <button type="submit">
                            <FormattedMessage
                                id='login'
                                defaultMessage='Login'
                            />
                        </button>
                    )}


                </form>
          </div>
      </div>
    )
}
export default Login