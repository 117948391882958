import React, {useState} from 'react'
import { IntlProvider } from 'react-intl'
import MensajesIngles from '../lang/en-US.json'
import MensajesItaliano from '../lang/it-IT.json'
import MensajesEspañol from '../lang/es-ES.json'

const langContext = React.createContext()

const LangProvider = ({children}) => {
    
    const localSistemLang = navigator.language;

    let localeDefault;
    let messagesDefault
    const localLang = localStorage.getItem('Lang')

    if(localLang) {
        localeDefault = localLang
    } else {
        localeDefault = localSistemLang
    }

    if(localeDefault === 'it-IT' ) {
        messagesDefault = MensajesItaliano
    } else if(localeDefault === 'en-US' ) {
        messagesDefault = MensajesIngles
    } else if(localeDefault === 'es-ES' ) {
        messagesDefault = MensajesEspañol
    } else {
        localeDefault = 'en-US'
        messagesDefault = MensajesIngles
    }

    const [lang, setLang] = useState(messagesDefault)
    const [locale, setLocale] = useState(localeDefault)

    const setLenguaje = (lenguaje) => {
        switch (lenguaje) {
            case 'it-IT':
                setLang(MensajesItaliano)
                setLocale(lenguaje)
                localStorage.setItem('Lang', lenguaje)
                break
            case 'en-US':
                setLang(MensajesIngles)
                setLocale(lenguaje)
                localStorage.setItem('Lang', lenguaje)
                break
            case 'es-ES':
                setLang(MensajesEspañol)
                setLocale(lenguaje)
                localStorage.setItem('Lang', lenguaje)
                break
            default: 
                setLang(MensajesIngles)
                setLocale('en-US')
                localStorage.setItem('Lang', 'en-US')
        }
    }

    return ( 
        <langContext.Provider value={{setLenguaje: setLenguaje, lang: lang}}>
            <IntlProvider locale={locale} messages={lang}>
                {children}
            </IntlProvider>
        </langContext.Provider>
     );
}
 
export { LangProvider, langContext };