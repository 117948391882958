import { faCloudArrowDown, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React from "react";

function Modal({imgRut, modal, setModal}) {

    const toggleModal = () => {
        setModal(!modal)
    }

    if(modal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }

    return(
        <>
            {modal && (
                <div>
                    <div className="overlay" onClick={toggleModal}></div>
                    
                    <div className="popup">
                        
                        <div className="modal__content">
                            <div className="modal__content__img">
                                <img src={imgRut.link} alt="preview img"/>
                            </div>
                            <button className="boton close-modal" onClick={toggleModal} ><FontAwesomeIcon icon={faXmark} /></button>
                        </div>
                    </div>
                </div>
            )}

        </>
    )
}

export default Modal