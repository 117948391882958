import React from 'react'

function Content() {

    return(
      <div className="dashboard-content">
        
      </div>
    )
}
export default Content