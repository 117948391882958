import React, { Fragment, useEffect, useRef, useState } from 'react'
import ArticleContent from './ArticleContent'
import Settings from './Settings'
import Content from './Content'
import GeneralBar from './GeneralBar'
import Header from '../layout/Header'
import OpenTicket from '../Home/OpenTicket'
import clienteAxios from '../../../config/axios'
import { useParams } from 'react-router-dom'

function GeneralSettings() {
    const headingRefs = useRef({});
    const [article, setArticle] = useState({
        content: "",
        categories: [{}]
      });
      const { id } = useParams()
    
    useEffect(() => {
        const consultaApi = async () => {
            const res = await clienteAxios.get(`/api/article/${id}`)
            if(res.data.data) {
                setArticle(res.data.data)
            }
        
            const viewsMasUno = {
                id,
                views: res.data.data.views + 1
            }
            await clienteAxios.put('/api/article/views', viewsMasUno)
        }
        consultaApi()
        // eslint-disable-next-line
    }, []);
    return(
        <Fragment>
            <Header />
            <GeneralBar/>
            <div className='contenedor-big general-settings'>
                <Settings html={article} headingRefs={headingRefs}/>
                <ArticleContent html={article} headingRefs={headingRefs} />
                <Content article={article}/>
            </div>

            <div className='contenedor'>
                <OpenTicket article={article}/>
            </div>
            
        </Fragment>

    )
}

export default GeneralSettings