import React, { useState, useEffect, useContext } from 'react';
import clienteAxios from '../../../config/axios';
import { PageContext } from '../../../context/pageContext';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp, faFile, faFilePdf, faTrash } from '@fortawesome/free-solid-svg-icons';
const OpenTicket = ({article}) => {
    const navigate = useNavigate()
    const [ online24 ] = useContext(PageContext)
	const [content, setContent] = useState('');
	const [products, setProducts] = useState([]);
	const [prevFiles, setPrevFiles] = useState([{type: '', url: "", name: ''}]);
	const [files, setFiles] = useState([]);
	const [ticket, setTicket] = useState({
        product: ''
    })
	const [active, setActive] = useState(false)
	const [inProcess, setInProcess] = useState(false)

    useEffect(() => {
        setTicket({product: article?.categories[0]?.productId})
        // eslint-disable-next-line
    }, [article?.categories[0]?.productId])

    const openTicketClick = () => {
        const userData = localStorage.getItem('userData');
        
        if(!userData) {
            const currentURL = window.location.pathname;
            localStorage.setItem('prevUrl', currentURL)
            navigate('/login')
        } else {
            setActive(!active)
        }
    }

    useEffect(() => {
        const consultaApi = async () => {
          try {
            const res = await clienteAxios.get(`/api/instance/${online24.id}/products`)
            
            if(res.data?.data) {
                setProducts(res.data.data)
            }

          } catch (error) {}
        }
        if(online24.id) {
            consultaApi()
        }
        // eslint-disable-next-line
    }, [online24])

    const handlerTicket = (e) => {
        setTicket({
            ...ticket,
            [e.target.name]: e.target.value
        });
    };

    const onSumitTicket = async (e) => {
        setInProcess(true)
        let userData = localStorage.getItem('userData');
        if (userData) {
            userData = JSON.parse(userData);
        } else {
            navigate('/login')
        }
        const ticketReq = {
            USER_ID: userData[0].UserId,
            MESSAGE: content,
            PRODUCT_ID: ticket.product,
            FILES: files,
            "CLIENT_TYPE": userData[0].CustomerClientType,
            "CLIENT_ID": userData[0].CustomerClientId,
            "STATUS_ID": userData[0].StatusId,
            "SUBSCRIPTION_PLAN_ID": userData[0].SubscriptionPlanId,
            "CATEGORY_ID": userData[0].CategoryId,
            "LINE_NAME": userData[0].LineId,
        }
        console.log(ticketReq); 
        try {
            const res = await clienteAxios.post(`/api/instance/${online24.id}/service/24ONLINE/ticket`, ticketReq)
            console.log(res.data)
                navigate('/dashboard/tickets')
        } catch (error) {
            
        }
        setInProcess(false)
    }

    const reedFiles = async e => {
        try {
            const formData = new FormData()
            formData.append('file', e.target.files[0])
            const res = await clienteAxios.post(`/api/uploadFile/${online24.id}/tickets`, formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            })
            if (res.data) {
                const preView = {type: e.target.files[0].type, url: res.data.fileUrl, name: e.target.files[0].name}
                setPrevFiles([...prevFiles, preView])
                setFiles([...files, res.data.fileUrl])
            }
        } catch (error) {
            console.log(error)
        }
    }

    const deleteFileUrl = (prevFile) => {
        const newFiles = files.filter(item => item !== prevFile.url);
        const newPrevFiles = prevFiles.filter(item => item.url !== prevFile.url);
        setFiles(newFiles)
        setPrevFiles(newPrevFiles)
    }
    
	return (
        <>
            <div className={`card-custom accordion ${active ? 'active': null}`} >

                
                <h2 className="no-padding no-margin" onClick={openTicketClick}>
                    Did not find an answer to <span className='question-span'>your question?</span>
                </h2>
                <p>Open a ticket and send your question to help you</p>

                <i className="bi bi-arrow-down-circle" onClick={() => setActive(!active)}></i>
                

                <form className='accordion__content' >
                    <div className="open-ticket__producto">
                        <img
                            src='/img/img4.svg'
                            alt="answer"
                        />

                        <div className='open-ticket__inputs'>
                            {/* <input type='text' placeholder='Title...' className='campo' name='title' onChange={handlerTicket}/> */}

                            {/* <label for="product" className='custom-select'>Product: */}
                            <select value={ticket.product} className='campo' id='product' name='product' onChange={handlerTicket}>
                                <option value="" disabled>-- Select a Product --</option>
                                {products.map(product => (
                                    <option key={product.id} value={product.id}>{product.name}</option>
                                ))}
                            </select>
                            {/* </label> */}

                            <div className="checkbox">
                                <input
                                    type="checkbox"
                                    name="agree"
                                    id="agree"
                                />
                                <label for='agree' className='no-margin'>
                                    I agree to the <span>processing of personal data</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='jodit-ticket'>
                        <textarea
                            rows={8}
                            className="campo textarea-full"
                            name="question"
                            placeholder="Write your question"
                            onChange={(e) => setContent(e.target.value)}
                        >
                        </textarea>
                    </div>

                    {inProcess ? (
                        <div className="content-loader-login">
                            <span className="loader-login"></span>
                        </div>
                    ) : (
                        <button className='boton boton--primario' type='button' onClick={onSumitTicket}>Open ticket</button>
                    )}

                    <button className='boton boton--primario boton--file' type='button' >
                        <input
                            className='hidden'
                            type="file"
                            name="files"
                            onChange={reedFiles}
                        />
                        Upload files <FontAwesomeIcon icon={faCloudArrowUp} />
                    </button>
                    
                    <div className='preview-files'>
                        {prevFiles.map((prevFile, i) => {
                            // eslint-disable-next-line
                            if(prevFile.type == 'image/gif' || prevFile.type == 'image/vnd.ms-photo' || prevFile.type == 'image/jpe' || prevFile.type == 'image/jpg' || prevFile.type == 'image/png' || prevFile.type == 'image/svg+xml') {
                                return (
                                    <div key={i} className='preview-file' onClick={() => deleteFileUrl(prevFile)}>
                                        <img src={prevFile.url} alt='pre'/>
                                        <FontAwesomeIcon className="delete-file" icon={faTrash} />
                                    </div>
                                )
                                // eslint-disable-next-line
                            } else if(prevFile.type == 'application/pdf') {
                                return (
                                    <div key={i} className='preview-file' onClick={() => deleteFileUrl(prevFile)}>
                                        <FontAwesomeIcon icon={faFilePdf} />
                                        <FontAwesomeIcon className="delete-file" icon={faTrash} />
                                    </div>
                                )
                            } else if(!prevFile.type) {
                                return null
                            } else {
                                return (
                                    <div key={i} className='preview-file' onClick={() => deleteFileUrl(prevFile)}>
                                        <FontAwesomeIcon icon={faFile} />
                                        <FontAwesomeIcon className="delete-file" icon={faTrash} />
                                    </div>
                                )
                            }
                        })}
                    </div>
                </form>
            </div>
        </>
	);
}
 
export default OpenTicket;