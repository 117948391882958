import React, { useContext } from 'react'
import { PageContext } from '../../../context/pageContext'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fab);

function Footer() {
    // eslint-disable-next-line
    const [ online24, _setOnline24, page ] = useContext(PageContext)
    
    return(
        <>
            {online24.isActive ? (
                <footer className='footer margin-0-auto '>

                    <div className="barra margin-top-bottom display-flex ">
        
                        <div className="barra__izquierda">
        
                            <div className='flex-column-center max-w-13'>   
                                <Link className="logo" to="/">
                                    {page.logo? (
                                        <img className="" src={page.logo} alt="logo" />
                                    ) :  null}
                                </Link>
                            </div>
        
                            {/* <nav className="navegacion active">
                                <a href="#" className="navegacion__enlace">General</a>
                                <p className='no-margin'>•</p>
                                <a href="#" className="navegacion__enlace">Documents</a>
                                <p className='no-margin'>•</p>
                                <a href="#" className="navegacion__enlace">Settings</a>
                                <p className='no-margin'>•</p>
                                <a href="#" className="navegacion__enlace">Support</a>
                                <p className='no-margin'>•</p>
                                <a href="#" className="navegacion__enlace">Billing and Payments</a>
                                <p className='no-margin'>•</p>
                                <a href="#" className="navegacion__enlace">Other</a>
                            </nav> */}
                        </div>
        
                        <div className="barra__derecha">
                            {online24.socialNetworks && online24.socialNetworks.length !== 0 ? online24.socialNetworks.map(social => {
                                
                                return (
                                    <a key={social.id} href={social.url}>
                                        <FontAwesomeIcon className='social' icon={fab[social.icon]}  />
                                    </a>
                                )
                            }) : null}
                        </div>
                    </div>
        
                    <div className='copyrights display-flex '>
                        <p>©2023. {page.company}. All rights reserved</p>
                        <p>Privacy Policy</p>
                    </div>
        
                </footer>
            ) : null}
        </>

    )
}

export default Footer