import React, { useContext, useEffect } from 'react'
import { PageContext } from '../../../context/pageContext'
import { useNavigate } from 'react-router-dom'

function Category() {
    const [ online24 ] = useContext(PageContext)
    const navigate = useNavigate()

    useEffect(() => {
        if(online24.id && online24) {
            navigate('/')
        }
    }, [online24])

    return(
        <div className='inactive'>

            <p className='centrar-texto error500'>Error <span className='span-h2--naranja'>500</span></p>
            <h2 className='centrar-texto'>Something <span className='span-h2--naranja'>went</span> wrong... :(</h2>
            <img
                src='/img/error.svg'
                alt="answer"
            />
        </div>
    )
} 

export default Category