
import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl';
import { faBuilding } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function TicketList() {
    const navigate = useNavigate()
	const [userData, setUserData] = useState([]);

    useEffect(() => {
        let localUserData = localStorage.getItem('userData');
        if (localUserData) {
            localUserData = JSON.parse(localUserData);
            setUserData(localUserData)
        } else {
            const currentURL = window.location.pathname;
            localStorage.setItem('prevUrl', currentURL)
            navigate('/login')
        }

    }, [])


    return(
        <div className="dashboard-content" >
            <div >
                <h2>Companies:</h2>
                <article className="tickets-bar">
                    {userData.map((data, userIndex) => (
                        
                        
                            <div key={userIndex} className='ticket display-flex ticket--companies'>
                                <FontAwesomeIcon icon={faBuilding} />
                                    <p className='no-margin'>#{data.CustomerClientId}</p>
                                <Link to={`/dashboard/tickets-management/${data.CustomerClientId}`}>{data.CustomerName}</Link>
                            </div>
                            
                        
                    ))}
                    {/* Paginación */}

                    <div className="display-flex separador mt-5">
                        <nav className="paginador display-flex">
                            <div className="paginador__back">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12"
                                    height="8"
                                    viewBox="0 0 12 8"
                                    fill="none">
                                    <path
                                        d="M0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659728 4.53553 0.464465C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM12 3.5L1 3.5L1 4.5L12 4.5L12 3.5Z"
                                        fill="#2F7CDA"
                                    />
                                </svg>

                                <button disabled>
                                    <FormattedMessage
                                        id='back'
                                        defaultMessage='Back'
                                    />
                                </button>
                            </div>
                        
                                <button className={`paginador__pages active`} >1</button>
                            

                            <div className="paginador__next">
                                <button disabled>
                                    <FormattedMessage
                                        id='next'
                                        defaultMessage='Next'
                                    />
                                </button>

                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12"
                                    height="8"
                                    viewBox="0 0 12 8"
                                    fill="none">
                                    <path
                                        d="M11.3536 3.64645C11.5488 3.84171 11.5488 4.15829 11.3536 4.35355L8.17157 7.53553C7.97631 7.7308 7.65973 7.7308 7.46447 7.53553C7.2692 7.34027 7.2692 7.02369 7.46447 6.82843L10.2929 4L7.46447 1.17157C7.2692 0.97631 7.2692 0.659728 7.46447 0.464465C7.65973 0.269203 7.97631 0.269203 8.17157 0.464465L11.3536 3.64645ZM-4.37121e-08 3.5L11 3.5L11 4.5L4.37121e-08 4.5L-4.37121e-08 3.5Z"
                                        fill="#2F7CDA"
                                    />
                                </svg>
                            </div>

                        </nav>
                    </div>

                </article>
            </div>
        </div>
    )
}
export default TicketList