import React from 'react'
import Sidebar from './Sidebar';
import Header from './Header';
import Content from './Content';
import TicketList from './Tickets/TicketList.js';
import Ticket from './Tickets/Ticket.js'

import { Route, Routes } from 'react-router-dom';
import Tickets from './Tickets/Tickets.js';

function Dashboard() {

    return(
      <div className="dashboard no-margin no-padding">
        <Header/>

        <Routes>
            <Route exact path="/tickets" element={<TicketList />} />
            <Route exact path="/tickets-management/:CustomerClientId" element={<Tickets />} />
            <Route exact path="/tickets/:id" element={<Ticket />} />
            <Route exact path="/" element={<Content/>} />
        </Routes>

        <Sidebar/>
      </div>
    )
}
export default Dashboard