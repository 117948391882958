import React, {Fragment} from 'react'
import OpenCategory from '../layout/OpenCategory'

function Seac({rutaRelativa, contenidoDelP, contenidoH2, company}) {
    return(
        <Fragment>
            <h2 className='centrar-texto'>{contenidoH2} <span className='span-h2--azul'> {company}</span></h2>

            <div className='con-sidebar separador display-flex'>
                <OpenCategory/>
               

                <div className='article'>
                    <div className='flex-column-center'>
                        <img src={`${rutaRelativa}`} alt="school" className='margin-0-auto'/>
                        <p>{contenidoDelP}</p>
                    </div>
                </div>

            </div>

        </Fragment>
    )
}

export default Seac