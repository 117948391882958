import React,{Fragment, useContext} from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

//Home
import Home from './components/KbPages/Home/Home';
import Footer from './components/KbPages/layout/Footer';

//Searching
import Searching from './components/KbPages/Searching/Searching';
//GeneralSettings
import Article from './components/KbPages/Articles/Article';
//Chat Bot
import ChatBot from './components/KbPages/ChatBot/ChatBot';

//Dashboard
import Login from './components/Dashboard/Login';
import Register from './components/Dashboard/Register';

import Dashboard from './components/Dashboard/Dashboard';
import { PageContext, PageProvider } from './context/pageContext';
import { LangProvider, langContext } from './context/langContext';

import IdleService from './components/KbPages/Pages/IdleService'
import Erro from './components/KbPages/Pages/Erro'

function App() {
  const [ online24, setOnline24, page, setPage, loading, setLoading ] = useContext(PageContext)


  return(
    <Router>
      <LangProvider >
          <PageProvider value={[ online24, setOnline24, page, setPage, loading, setLoading ]} >
              <Fragment>

                  <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/searching" element={<Searching />} />
                      <Route path="/article/:id" element={<Article />} />
                      <Route path="/chat-bot" element={<ChatBot />} />

                      <Route path="/login" element={<Login />} />
                      <Route path="/register" element={<Register />} />
                      <Route path="/dashboard/*" element={<Dashboard />} />
                      <Route path="/inactive" element={<IdleService />} />
                      <Route path="/erro" element={<Erro />} />
                  </Routes>

                  <Footer />
                  
              </Fragment>
            </PageProvider>
        </LangProvider>
    </Router>
  )
}

export default App;
