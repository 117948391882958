import React, { Fragment } from 'react'

function ResultsCards(props) {
    const { card, categories } = props


    const formatearFecha = (item) => {
        const fecha = new Date(item)
        return fecha.toLocaleDateString('en-US', { 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric' 
        });
    }
    const formattedCreateDate = formatearFecha(card.createdDate)
    const formattedUpdateDate = formatearFecha(card.updatedDate)

    const handleParseTitle = () => {
        // Parsear el HTML usando DOMParser
        const parser = new DOMParser();
        const doc = parser.parseFromString(card.content, 'text/html');
      
        // Obtener el título (h1, h2, h3, h4, h5, h6)
        const titleElement = doc.querySelector('h1, h2, h3, h4, h5, h6');
        const title = titleElement ? titleElement.textContent : '';
        return title
      
    };

    const handleParseContent = () => {
        // Parsear el HTML usando DOMParser
        const parser = new DOMParser();
        const doc = parser.parseFromString(card.content, 'text/html');

        // Obtener el contenido de los párrafos (p) excluyendo los elementos <br>
        const paragraphs = doc.querySelectorAll('p');
        const content = Array.from(paragraphs).map(p => {
          // Filtrar los elementos <br> y excluirlos del contenido
          const nonBrNodes = Array.from(p.childNodes).filter(node => node.nodeName !== 'BR');
          return nonBrNodes.map(node => node.textContent).join(' ');
        }).join('\n');
         return content
    };

    const filterCategories = (categoryId) => {
        
        const categoriaClicked = categories.find((categoria) => categoria.id === categoryId);

        if(categoriaClicked) {
            return <button type='button' key={categoryId}>{categoriaClicked.name}</button>
        } else return null
        
    }
    
    
    return(
        <Fragment>
    
            <div className="card" >
                <h3>{handleParseTitle()}</h3>

                <p className='card-p'>{handleParseContent()}</p>

                {/* <p className='card-p'>
                    <div dangerouslySetInnerHTML={{ __html: card.html }} />
                </p> */}

                {card.categoryIds ? (
                    <div className='card-categories'>
                        {card.categoryIds.map((categoryId) => (
                            filterCategories(categoryId)
                        ))}
                    </div>
                ) : null}


                <div className='card-data display-flex'>
                    <div className='card-data__time'>
                        <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.04742 11.5039H8.95258C10.081 11.5039 11 10.5866 11 9.45645V2.77129C11 1.6429 10.081 0.723871 8.95258 0.723871H8.3671V0.354839C8.3671 0.159677 8.20742 0 8.01226 0C7.8171 0 7.65742 0.159677 7.65742 0.354839V0.723871H3.60161V0.354839C3.60161 0.159677 3.44194 0 3.24677 0C3.05161 0 2.89194 0.159677 2.89194 0.354839V0.723871H2.04742C0.919032 0.723871 0 1.64113 0 2.77129V9.45645C0.00177419 10.5848 0.919032 11.5039 2.04742 11.5039ZM0.711452 2.77129C0.711452 2.03323 1.31113 1.43355 2.04919 1.43355H2.89371V2.13968C2.89371 2.33484 3.05339 2.49452 3.24855 2.49452C3.44371 2.49452 3.60339 2.33484 3.60339 2.13968V1.43355H7.66097V2.13968C7.66097 2.33484 7.82064 2.49452 8.01581 2.49452C8.21097 2.49452 8.37064 2.33484 8.37064 2.13968V1.43355H8.95613C9.69419 1.43355 10.2939 2.03323 10.2939 2.77129V3.61048H0.713226V2.77129H0.711452ZM0.711452 4.32016H10.2921V9.45645C10.2921 10.1945 9.69242 10.7942 8.95436 10.7942H2.04742C1.30935 10.7942 0.709677 10.1945 0.709677 9.45645V4.32016H0.711452Z" fill="#64748B"/>
                        </svg>

                        <p className='no-margin'>{formattedCreateDate}</p>

                        {formattedCreateDate !== formattedUpdateDate ? (

                            <p className='no-margin'>- {formattedUpdateDate}</p>
                        ) : null }
                    </div>

                    <div className='card-data__views'>
                        <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.1483 5.78365C19.1349 4.02544 17.6762 2.56519 15.9191 1.54988C14.162 0.534559 12.1684 0 10.1391 0C8.10969 0 6.11613 0.534559 4.35901 1.54988C2.60189 2.56519 1.14322 4.02544 0.129808 5.78365L0 6L0.129808 6.21635C1.14322 7.97456 2.60189 9.43481 4.35901 10.4501C6.11613 11.4654 8.10969 12 10.1391 12C12.1684 12 14.162 11.4654 15.9191 10.4501C17.6762 9.43481 19.1349 7.97456 20.1483 6.21635L20.2781 6L20.1483 5.78365ZM10.1401 11.1339C8.30564 11.1308 6.5026 10.6573 4.9034 9.75842C3.30419 8.85958 1.9623 7.56553 1.00601 6C1.95986 4.4322 3.30121 3.1363 4.90095 2.23703C6.50069 1.33777 8.30497 0.865402 10.1401 0.865402C11.9753 0.865402 13.7796 1.33777 15.3793 2.23703C16.9791 3.1363 18.3204 4.4322 19.2743 6C18.316 7.56368 16.9736 8.85626 15.3749 9.75485C13.7761 10.6534 11.9741 11.1282 10.1401 11.1339Z" fill="#64748B"/>
                            <path d="M10.1403 2.41016C9.4311 2.41059 8.73796 2.62136 8.14858 3.01582C7.5592 3.41027 7.10006 3.97069 6.82926 4.62615C6.55845 5.28161 6.48815 6.00267 6.62724 6.69809C6.76634 7.39352 7.10857 8.03207 7.61066 8.53294C8.11275 9.03382 8.75212 9.37451 9.44788 9.51193C10.1436 9.64934 10.8645 9.57729 11.5193 9.30491C12.1741 9.03252 12.7334 8.57203 13.1265 7.9817C13.5195 7.39136 13.7286 6.69772 13.7273 5.98852C13.7239 5.03904 13.3446 4.12955 12.6724 3.45897C12.0002 2.78839 11.0898 2.41129 10.1403 2.41016ZM10.1403 9.04765C9.53823 9.04594 8.95016 8.86597 8.45024 8.53046C7.95032 8.19494 7.56096 7.7189 7.33125 7.16238C7.10154 6.60585 7.04178 5.99377 7.1595 5.40332C7.27722 4.81287 7.56715 4.2705 7.99273 3.84463C8.41831 3.41875 8.96047 3.12843 9.55083 3.01029C10.1412 2.89216 10.7533 2.95148 11.31 3.1808C11.8667 3.41011 12.343 3.79914 12.6789 4.29882C13.0148 4.7985 13.1951 5.38645 13.1973 5.98852C13.1984 6.39039 13.1201 6.78852 12.9669 7.16005C12.8137 7.53157 12.5886 7.86916 12.3046 8.15343C12.0205 8.4377 11.6831 8.66303 11.3117 8.81649C10.9402 8.96995 10.5422 9.04851 10.1403 9.04765Z" fill="#64748B"/>
                        </svg>

                        <p className='no-margin'>{card.views}</p>
                    </div>
                </div>
            </div>  

        </Fragment>
    )
}

export default ResultsCards