import React, { Fragment, useContext, useEffect, useState } from "react";
import Questions from "./Question";
import clienteAxios from "../../../config/axios.js";
import { PageContext } from "../../../context/pageContext.js";

function FrequentlyAsked({ home, rutaRelativa, contenidoH2 }) {
  const [ online24 ] = useContext(PageContext)
  const [boton, guardarBoton] = useState(null);
  const [faqCategories, setFaqCategories] = useState([]);
  const [faq, setFaq] = useState([]);
  const [faqsContainer, setFaqsContainer] = useState([]);
  const [activeCategory, setActiveCategory] = useState(0);

  useEffect(() => {
    //Get a: /api/instance/${}/faqCategories
    const consultaApi = async () => {
      const res = await clienteAxios.get(`/api/instance/${online24.id}/faqCategories`)

      if(res.data.data) {
        setFaqCategories(res.data.data)

        consultaFaq(res.data.data[0]?.id, 0)
      }
    }
    // const consulta = {
    //   data: [
    //     {id: 99, name:'faqCategory 1', "bitrixInstanceId": 2},
    //     {id: 21, name:'faqCategory 2', "bitrixInstanceId": 2},
    //     {id: 22, name:'faqCategory 4', "bitrixInstanceId": 2},
    //     {id: 106, name:'faqCategory 3', "bitrixInstanceId": 2}
    //   ]
    // }

    consultaApi()
    // eslint-disable-next-line
  }, []);

  const consultaFaq = async (faqId, index) => {
    setActiveCategory(index)

    try {
      if(faqsContainer.length === 0) {
        const res = await clienteAxios.get(`/api/instance/${online24.id}/faqs/20`)

        if(res.data.data) {
          setFaqsContainer(res.data.data)
          const faqsClicked = res.data.data.filter((faq) => faq.faqCategory.id === faqId);
          setFaq(faqsClicked);
        }
      } else {
        const faqsClicked = faqsContainer.filter((faq) => faq.faqCategory.id === faqId);
        setFaq(faqsClicked);
      }
    } catch (error) {
      
    }
 
    //Get a: /api/instance/${}/faqCategory/${faqId}/faqs
    // const faqs = [
      
    //   {id:1 ,faqCategoryId: 99, answer: 'Ipsum et irure non cupidatat. Dolore ex minim eiusmod laborum dolore reprehenderit excepteur in mollit id laboris. Mollit ipsum sit irure cillum.', question: 'Can I view documents and pictures online?'},
    //   {id: 2,faqCategoryId: 99, answer: 'Ipsum et irure non cupidatat. Dolore ex minim eiusmod laborum dolore reprehenderit excepteur in mollit id laboris. Mollit ipsum sit irure cillum.', question: 'What is a knowledge base?'},
    //   {id:3 ,faqCategoryId: 99, answer: 'Ipsum et irure non cupidatat. Dolore ex minim eiusmod laborum dolore reprehenderit excepteur in mollit id laboris. Mollit ipsum sit irure cillum.', question: 'How can i setup my user profile?'},
    //   {id: 4,faqCategoryId: 99, answer: 'Ipsum et irure non cupidatat. Dolore ex minim eiusmod laborum dolore reprehenderit excepteur in mollit id laboris. Mollit ipsum sit irure cillum.', question: 'What makes a good knowledge base?'},

    //   {id: 5,faqCategoryId: 21, answer: 'Ipsum et irure non cupidatat. Dolore ex minim eiusmod laborum dolore reprehenderit excepteur in mollit id laboris. Mollit ipsum sit irure cillum.', question: 'What makes a good knowledge base?'},
    //   {id: 6,faqCategoryId: 21, answer: 'Ipsum et irure non cupidatat. Dolore ex minim eiusmod laborum dolore reprehenderit excepteur in mollit id laboris. Mollit ipsum sit irure cillum.', question: 'Can I view documents and pictures online?'},
    //   {id: 7,faqCategoryId: 21, answer: 'Ipsum et irure non cupidatat. Dolore ex minim eiusmod laborum dolore reprehenderit excepteur in mollit id laboris. Mollit ipsum sit irure cillum.', question: 'What is a knowledge base?'},
      
    //   {id: 32,faqCategoryId: 22, answer: 'Ipsum et irure non cupidatat. Dolore ex minim eiusmod laborum dolore reprehenderit excepteur in mollit id laboris. Mollit ipsum sit irure cillum.', question: 'What is a knowledge base?'},
     
    //   {id: 8,faqCategoryId: 106, answer: 'Ipsum et irure non cupidatat. Dolore ex minim eiusmod laborum dolore reprehenderit excepteur in mollit id laboris. Mollit ipsum sit irure cillum.', question: 'Can I view documents and pictures online?'},
    //   {id: 9,faqCategoryId: 106, answer: 'Ipsum et irure non cupidatat. Dolore ex minim eiusmod laborum dolore reprehenderit excepteur in mollit id laboris. Mollit ipsum sit irure cillum.', question: 'What is a knowledge base?'},
    //   {id: 10,faqCategoryId: 106, answer: 'Ipsum et irure non cupidatat. Dolore ex minim eiusmod laborum dolore reprehenderit excepteur in mollit id laboris. Mollit ipsum sit irure cillum.', question: 'How can i setup my user profile?'},
    //   {id: 11,faqCategoryId: 106, answer: 'Ipsum et irure non cupidatat. Dolore ex minim eiusmod laborum dolore reprehenderit excepteur in mollit id laboris. Mollit ipsum sit irure cillum.', question: 'What makes a good knowledge base?'},
    //   {id: 12,faqCategoryId: 106, answer: 'Ipsum et irure non cupidatat. Dolore ex minim eiusmod laborum dolore reprehenderit excepteur in mollit id laboris. Mollit ipsum sit irure cillum.', question: 'What makes a good knowledge base?'},
    
    // ]
  
  };

  return (
    <Fragment>
      <h2 className="centrar-texto">{contenidoH2}</h2>

      <div className="asked separador">
        <section className="section">

          <ul className="categories no-margin no-padding">
            {faqCategories.map((faqCategory, index) => (
              <li
                key={faqCategory.id}
                onClick={() => consultaFaq( faqCategory.id, index )}
                className={`${activeCategory === index ? "active" : null}`}
              >
                <p className="category-answer">{faqCategory.name}</p>
              </li>
            ))}


          </ul>

          <div className="questions">
            {faq.map((item, i) => (
              <Questions
                item={item}
                i={i}
                key={i}
                guardarBoton={guardarBoton}
                boton={boton}
              />
            ))}
          </div>
        </section>

        <div className="display-flex flex-center">
          <img src={`${rutaRelativa}`} alt='img' />
        </div>
      </div>
    </Fragment>
  );
}
export default FrequentlyAsked;
