import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'

function GeneralBar(){
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate()

    const handleSearch = (e) => {
        e.preventDefault();
        // Redirecciona al componente de búsqueda y pasa el término de búsqueda como parámetro
        navigate(`/searching?term=${encodeURIComponent(searchTerm)}`);
  };

    return(
        <Fragment>
            <form className='general-bar display-flex position-general' onSubmit={handleSearch}>
            
                {/* <nav className="navegacion">
                    <a href="#" className="navegacion__enlace navegacion__enlace--blanco">General</a>
                    <p className='no-margin navegacion__enlace--blanco'>/</p>
                    <a href="#" className="navegacion__enlace navegacion__enlace--blanco">Documents</a>
                    <p className='no-margin navegacion__enlace--blanco'>/</p>
                    <a href="#" className="navegacion__enlace navegacion__enlace--blanco">Settings</a>
                    <p className='no-margin navegacion__enlace--blanco'>/</p>
                    <a href="#" className="navegacion__enlace navegacion__enlace--blanco">Support</a>
                    <p className='no-margin navegacion__enlace--blanco'>/</p>
                    <a href="#" className="navegacion__enlace navegacion__enlace--blanco">Billing and Payments</a>
                    <p className='no-margin navegacion__enlace--blanco'>/</p>
                    <a href="#" className="navegacion__enlace navegacion__enlace--blanco">Other</a>
                </nav> */}

                <input 
                    type="text" 
                    className="search-input " 
                    placeholder="Make a reqest" 
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />

                {/* <button className='position-a' type='submit'>
                    <img src="/img/Group.svg" alt="Ícono SVG" />
                </button> */}
            
            </form>

        </Fragment>



    )
}

export default GeneralBar