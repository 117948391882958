import React, { Fragment, useState, useEffect, useContext } from "react";
import SideMenu from "../layout/SideMenu";
import ResultsCards from "./ResultsCards";
import Results404 from "./Results404";
import clienteAxios from "../../../config/axios";
import { Link, useLocation } from "react-router-dom";
import Header from "../layout/Header";
import { PageContext } from "../../../context/pageContext";
import { FormattedMessage } from 'react-intl';

function Searching() {
  // eslint-disable-next-line
  const [ online24, _setOnline24, _page, _setPage, loading, setLoading ] = useContext(PageContext)
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const searchTermHome = queryParams.get("term");
  // eslint-disable-next-line
  const [userId, setUserId] = useState(1);
  const [articles, setArticle] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [currentLimit, setCurrentLimit] = useState(10);
  const [categories, guardarCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    const consultaApi = async () => {
      setLoading(true)
      const res = await clienteAxios.get(`/api/instance/${online24.id}/categories`)
      if(res.data.data) {
        guardarCategory(res.data.data)
      } else {
        guardarCategory([])
      }
      setLoading(false)
    }
    if(online24.id) {
      consultaApi()
    }
    // eslint-disable-next-line
  }, [online24]);

  const consultarApiArticle = async (
    page = 1,
    limit = currentLimit,
    searchTermParam = searchTerm,
    id = userId.userId
  ) => {
    try {
      // Construye la URL base para la búsqueda
      let url
      // Si hay una categoría seleccionada, añade el parámetro categoryId a la URL
      if (!selectedCategory) {
        if(!searchTermParam) {
          url = `/api/instance/${online24.id}/articles/${page}/${limit}`;
        } else {
          url = `/api/instance/${online24.id}/articles/${page}/${limit}/${searchTermParam}`;
        }
      } else {
        url = `/api/instance/${online24.id}/category/${selectedCategory}/articles/${page}/${limit}`;
      }
      // Realiza la solicitud con la URL que incluye todos los parámetros necesarios
      const response = await clienteAxios.get(url);

      // Actualiza el estado con los datos de la respuesta

      if (response.data.data) {
        setArticle(response.data.data);
      } else {
        setArticle([]);
      }

      // Actualiza la información de paginación
      if (response.data.paginationInfo) {
        setTotalPages(response.data.paginationInfo.totalPages);
        setCurrentPage(response.data.paginationInfo.currentPage);
      } else {
        setTotalPages(10);
        setCurrentPage(1);
      }

      

    } catch (error) {
      console.error("Error al consultar la API", error);
    }
  };

  useEffect(() => {
      if (searchTermHome && online24.id) {
        setSearchTerm(searchTermHome);
        consultarApiArticle(
            1,
            currentLimit,
            searchTermHome,
            userId.userId
        );
      }
      // eslint-disable-next-line
  }, [searchTermHome, online24]);

  useEffect(() => {
    if(selectedCategory) {
      consultarApiArticle()
    }

    // eslint-disable-next-line
}, [selectedCategory]);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!searchTerm.trim()) return;
    consultarApiArticle();
  };

  const goToPage = (page) => {
    setCurrentPage(page);
    consultarApiArticle(page);
  };

  const renderPagination = () => {
    let paginationButtons = [];

    // Siempre muestra la primera página
    paginationButtons.push(renderPageButton(1));

    // Determina si es necesario mostrar puntos suspensivos después de las primeras páginas
    let showInitialPages = currentPage <= 3;
    let showFinalPages = currentPage > 3 && currentPage < totalPages - 2;

    // Mostrar las primeras tres páginas
    if (showInitialPages) {
      for (let i = 2; i <= 3; i++) {
        if (i < totalPages) {
          paginationButtons.push(renderPageButton(i));
        }
      }
    }

    // Si no estamos cerca del inicio, mostramos puntos suspensivos
    if (!showInitialPages) {
      paginationButtons.push(
        <span key="left-ellipsis" className="paginador__ellipsis">
          ...
        </span>
      );
    }

    // Si estamos en el medio, mostramos la página actual y las dos páginas que la rodean
    if (currentPage > 3 && currentPage < totalPages - 2) {
      paginationButtons.push(renderPageButton(currentPage - 1));
      paginationButtons.push(renderPageButton(currentPage));
      paginationButtons.push(renderPageButton(currentPage + 1));
    }

    // Si estamos cerca del final, mostramos puntos suspensivos
    if (showFinalPages) {
      paginationButtons.push(
        <span key="right-ellipsis" className="paginador__ellipsis">
          ...
        </span>
      );
    }

    // Siempre muestra la última página si hay más de una página
    if (totalPages > 1) {
      paginationButtons.push(renderPageButton(totalPages));
    }

    return paginationButtons;
  };

  // Función auxiliar para renderizar botones de páginas individuales
  const renderPageButton = (pageNumber) => (
    <button
      key={pageNumber}
      disabled={currentPage === pageNumber}
      onClick={() => goToPage(pageNumber)}
      className={`paginador__pages ${
        currentPage === pageNumber ? "active" : ""
      }`}>
      {pageNumber}
    </button>
  );

  // Función para ir a la siguiente página
  const nextPage = () => {
    if (currentPage < totalPages) {
      goToPage(currentPage + 1);
    }
  };

  // Función para ir a la página anterior
  const prevPage = () => {
    if (currentPage > 1) {
      goToPage(currentPage - 1);
    }
  };

  const showMoreResults = () => {
    const newLimit = currentLimit + 10;
    setCurrentLimit(newLimit);
    consultarApiArticle(currentPage, newLimit);
  };

  return (
    <Fragment>
        {loading ? (
            <div className="content-loader">
                <span className="loader"></span>
            </div>
        ): (
            <div>
              <Header />

              <div className="contenedor">
                <h2 className="centrar-texto">
                    <FormattedMessage
                        id='searching'
                        defaultMessage='Searching'
                    />
                  <span className="span-h2--azul">
                    <FormattedMessage
                        id='results'
                        defaultMessage='results'
                    />
                  </span>
                </h2>

                <div>

                  <div className="display-flex flex-center">
                    <form
                        className="searching-results position-r"
                        onSubmit={handleSearch}
                    >
                        <input
                            type="text"
                            className="searching-results__bar "
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />

                        <button
                            type="submit"
                            className="boton boton--secundario boton--busqueda"
                        >
                          <svg
                            width="11"
                            height="11"
                            viewBox="0 0 11 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M8.60814 7.69106C9.22209 6.88618 9.59302 5.87689 9.59302 4.79094C9.59302 2.14634 7.44419 0 4.79651 0C2.14884 0 0 2.14634 0 4.79094C0 7.43554 2.14884 9.58188 4.79651 9.58188C5.88372 9.58188 6.89419 9.21138 7.7 8.59814L9.91279 10.8084C10.0407 10.9361 10.207 11 10.3605 11C10.514 11 10.693 10.9361 10.8081 10.8084C11.064 10.5528 11.064 10.1568 10.8081 9.90128L8.60814 7.69106ZM1.27907 4.79094C1.27907 2.84901 2.85233 1.27758 4.79651 1.27758C6.7407 1.27758 8.31395 2.84901 8.31395 4.79094C8.31395 6.73287 6.72791 8.3043 4.79651 8.3043C2.86512 8.3043 1.27907 6.72009 1.27907 4.79094Z"
                              fill="white"
                            />
                          </svg>

                          <FormattedMessage
                            id='search'
                            defaultMessage='Search'
                          />
                      </button>
                    </form>
                  </div>

                  <div className="button-categories">
                        <button
                          className={`category ${selectedCategory === null ? "active" : ""}`}
                          type="button"
                          onClick={() => setSelectedCategory(null)}
                        >
                          <FormattedMessage
                            id='ticketsFiltersStatusAll'
                            defaultMessage='All'
                          /> <span></span>
                        </button>

                        {categories.map((category) => (
                          <button
                            key={category.id}
                            className={`category ${selectedCategory === category.id ? "active" : ""}`}
                            type="button"
                            onClick={() => setSelectedCategory(category.id)}
                          >
                            {category.name}
                          </button>
                        ))}
                    </div>

                </div>

                <SideMenu/>

                <div className="card-container">
                  {articles.map((card) => (
                    <Link to={`/article/${card.id}`}>
                      <ResultsCards card={card} key={card.id} categories={categories} />
                    </Link>
                  ))}
                </div>

                {articles.length > 0 && (
                  <div className="display-flex separador end-searching">
                    <button
                      className="boton boton--primario "
                      type="button"
                      onClick={showMoreResults}>
                        <FormattedMessage
                          id='searchingShowMore'
                          defaultMessage='Show More'
                        />
                    </button>

                    <nav className="paginador display-flex">
                      <div className="paginador__back">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="8"
                          viewBox="0 0 12 8"
                          fill="none">
                          <path
                            d="M0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659728 4.53553 0.464465C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM12 3.5L1 3.5L1 4.5L12 4.5L12 3.5Z"
                            fill="#2F7CDA"
                          />
                        </svg>

                        <button onClick={prevPage} disabled={currentPage === 1}>
                          <FormattedMessage
                            id='next'
                            defaultMessage='Next'
                          />
                        </button>
                      </div>

                      {renderPagination()}

                      <div className="paginador__next">
                        <button
                          onClick={nextPage}
                          disabled={currentPage === totalPages}
                        >
                          <FormattedMessage
                            id='back'
                            defaultMessage='Back'
                          />
                        </button>

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="8"
                          viewBox="0 0 12 8"
                          fill="none">
                          <path
                            d="M11.3536 3.64645C11.5488 3.84171 11.5488 4.15829 11.3536 4.35355L8.17157 7.53553C7.97631 7.7308 7.65973 7.7308 7.46447 7.53553C7.2692 7.34027 7.2692 7.02369 7.46447 6.82843L10.2929 4L7.46447 1.17157C7.2692 0.97631 7.2692 0.659728 7.46447 0.464465C7.65973 0.269203 7.97631 0.269203 8.17157 0.464465L11.3536 3.64645ZM-4.37121e-08 3.5L11 3.5L11 4.5L4.37121e-08 4.5L-4.37121e-08 3.5Z"
                            fill="#2F7CDA"
                          />
                        </svg>
                      </div>
                    </nav>
                  </div>
                )}

                {articles.length === 0 ? <Results404 searchTerm={searchTerm} /> : null}

              </div>
            </div>
        )}

    </Fragment>
  );
}

export default Searching;
