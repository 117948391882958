import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { PageContext } from '../../context/pageContext'
import { langContext } from '../../context/langContext';

function Header() {
// eslint-disable-next-line
    const [ _online24, _setOnline24, page, _setPage, ] = useContext(PageContext)
    const lang = useContext(langContext)

    return(
        <header className="header dboard-header">

            <div className="barra display-flex dboard-barra">

                <div className="barra__izquierda dboard-barra">

                    <div className='flex-column-center max-w-13'>   

                        <Link className="logo" to={'/'}>
                            {page.logo? (
                                <img className="" src={page.logo} alt="logo" />
                            ) :  null}
                        </Link>

                        {/* <button type='button' className='menu-h' onClick={toogleMenu}>
                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/></svg>

                        </button> */}

                    </div>

                    
                    {/* <nav className={`navegacion ${menu ? 'active' : ''}`}>
                        <a href="#" className="navegacion__enlace navegacion__enlace--primario">General</a>
                        <p className='no-margin'>•</p>
                        <a href="#" className="navegacion__enlace navegacion__enlace--primario">Documents</a>
                        <p className='no-margin'>•</p>
                        <a href="#" className="navegacion__enlace navegacion__enlace--primario">Settings</a>
                        <p className='no-margin'>•</p>
                        <a href="#" className="navegacion__enlace navegacion__enlace--primario">Support</a>
                        <p className='no-margin'>•</p>
                        <a href="#" className="navegacion__enlace navegacion__enlace--primario">Billing and Payments</a>
                        <p className='no-margin'>•</p>
                        <a href="#" className="navegacion__enlace navegacion__enlace--primario">Other</a>
                    </nav>  */}

                </div>

                <div className="barra__derecha margin_left">

                    <div className='display-flex dropdown-activator lang-dropdown jus justify-content-center'>
                        <button className='m-0 lang-dropdown__buttom'><i className="bi bi-translate"></i></button>

                        <ul className='dropdown no-margin no-padding'>
                            <li onClick={() => lang.setLenguaje('en-US')} ><Link className="dropdown-a lang" to={`#`}><img src='/img/united-states.png'/></Link></li>
                            <li onClick={() => lang.setLenguaje('it-IT')} ><Link className="dropdown-a lang" to={`#`}><img src='/img/italy.png'/></Link></li>
                            <li onClick={() => lang.setLenguaje('es-ES')} ><Link className="dropdown-a lang" to={`#`}><img src='/img/spain.png'/> </Link></li>
                        </ul>

                    </div>
                    {/* <i className="bi bi-search"></i>

                    <i className="bi bi-bell"></i> */}

                    <div className='btn-usser display-flex dropdown-activator'>

                        <div className='btn-usser__svg display-flex flex-center'>

                            <i className="bi bi-person-fill centrar-texto"></i>
   
                        </div>

                        <div className=''>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M16.8998 9.19844C16.4998 8.79844 15.8998 8.79844 15.4998 9.19844L11.9998 12.6984L8.4998 9.19844C8.0998 8.79844 7.4998 8.79844 7.0998 9.19844C6.6998 9.59844 6.6998 10.1984 7.0998 10.5984L11.2998 14.7984C11.4998 14.9984 11.6998 15.0984 11.9998 15.0984C12.2998 15.0984 12.4998 14.9984 12.6998 14.7984L16.8998 10.5984C17.2998 10.1984 17.2998 9.59844 16.8998 9.19844Z" fill="#706C92"/>
                            </svg>

                        </div>

                        <ul className='dropdown no-margin no-padding'>
                            <li><Link className="dropdown-a" to={`/`}>Home Page</Link></li>
                            <li><Link className="dropdown-a" to={`#`}>Profile</Link></li>
                        </ul>

                    </div>

                </div>
            </div>

        </header>

    )
}

export default Header