import React, { Fragment, useContext } from "react";
import Seac from "./Seac";
import NostreRisorse from "./NostreRisorse";
import FrequentlyAsked from "./FrequentlyAsked";
import Request from "../layout/Request";
import Header from "../layout/Header";
import { PageContext } from "../../../context/pageContext";

function Home() {
    // eslint-disable-next-line
    const [ _online24, _setOnline24, page, _setPage, loading ] = useContext(PageContext)

    const getPagesComponents = (position) => {
        return page.pageComponents.filter(item => item.position === position);
    };

    const renderComponent = (component) => {
        if (component.type === "custom") {
            // Si es un componente personalizado, inyecta directamente el HTML
            return (
                <div
                    className="scrolling"
                    key={component.id}
                    dangerouslySetInnerHTML={{ __html: component.content }}
                />
            );
        } else {
            // Renderizar componentes predefinidos
            switch (component.name) {
                case "Products":
                    const parserSeac = new DOMParser();
                    const docSeac = parserSeac.parseFromString(component.content, 'text/html');
                    const imgElementSeac = docSeac.querySelector('img'); // Encuentra la etiqueta img dentro del HTML
                    const pElementSeac = docSeac.querySelector('p'); // Encuentra la etiqueta p dentro del HTML
                    const h2ElementSeac = docSeac.querySelector('h2'); // Encuentra la etiqueta p dentro del HTML
                    let rutaRelativaSeac
                    let contenidoDelPSeac
                    let contenidoH2Seac 
                    
                    if (imgElementSeac) {
                        const urlImg = new URL(imgElementSeac.src);
                        if(urlImg.pathname === "/images/pages/img1.svg") {
                            rutaRelativaSeac = '/img/img1.svg'
                        } else {
                            rutaRelativaSeac = urlImg.href; // Obtiene la ruta relativa después del host
                        }
                        
                    }

                    if (pElementSeac) {
                        contenidoDelPSeac = pElementSeac.textContent; // Obtiene el contenido del párrafo
                    }

                    if (h2ElementSeac) {
                        contenidoH2Seac = h2ElementSeac.textContent; // Obtiene el contenido del párrafo
                    }
                return <Seac key={component.id} rutaRelativa={rutaRelativaSeac} contenidoDelP={contenidoDelPSeac} contenidoH2={contenidoH2Seac} company={page.company}/>;

                case "OurResources":
                    const ourParse = new DOMParser()
                    const ourDoc = ourParse.parseFromString(component.content, 'text/html');
                    const ourImgElement = ourDoc.querySelector('img');
                    const videoElement = ourDoc.querySelector('iframe'); // Encuentra la etiqueta img dentro del HTML
                    const ourH3 = ourDoc.querySelector('h3');
                    const ourH2 = ourDoc.querySelector('h2');

                    let rutaRelativaOur
                    let rutaRelativaVideo
                    let contenidoH3Our 
                    let contenidoH2Our 
                    
                    if (ourImgElement) {
                        const urlImg = new URL(ourImgElement.src);
                        if(urlImg.pathname === "/images/pages/img2.svg") {
                            rutaRelativaOur = '/img/img2.svg'
                        } else {
                            rutaRelativaOur = urlImg.href; 
                        }
                        
                    }

                    if (videoElement) {
                        const urlVideo = new URL(videoElement.src);
                        rutaRelativaVideo = urlVideo.href; 
                    }

                    if (ourH3) {
                        contenidoH3Our = ourH3.textContent; 
                    }

                    if (ourH2) {
                        contenidoH2Our = ourH2.textContent; 

                        // // Dividir la cadena en un array usando el espacio como separador
                        // let palabras = ourH2.textContent.split(" ");

                        // // Validar si hay más de una palabra
                        // if (palabras.length > 1) {
                        //     // Obtener la primera parte (todo excepto la última palabra)
                        //     let primerParte = palabras.slice(0, -1).join(" ");

                        //     // Obtener la última palabra
                        //     let ultimaPalabra = palabras[palabras.length - 1];

                        //     // Mostrar los resultados
                        //     console.log("Primer parte: " + primerParte);
                        //     console.log("Ultima palabra: " + ultimaPalabra);
                        // } else {
                        //     console.log("La cadena no tiene más de una palabra.");
                        // }
                    }
                    
                return (
                    <div className="scrolling">
                        <NostreRisorse key={component.id} urlImg={rutaRelativaOur} urlVideo={rutaRelativaVideo} h3Content={contenidoH3Our} h2Content={contenidoH2Our}/>
                    </div>
                )
                case "FAQS":
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(component.content, 'text/html');
                    const imgElement = doc.querySelector('img'); // Encuentra la etiqueta img dentro del HTML
                    const h2Element = doc.querySelector('h2');
                    let rutaRelativa
                    let contenidoH2

                    if (imgElement) {
                        const urlImg = new URL(imgElement.src);
                        if(urlImg.pathname === "/images/pages/img4.svg") {
                            rutaRelativa = '/img/img4.svg'
                        } else {
                            rutaRelativa = urlImg.href; // Obtiene la ruta relativa después del host
                        }
                    }

                    if (h2Element) {
                        contenidoH2 = h2Element.textContent; // Obtiene el contenido del párrafo
                    }
                
                return (
                    <div className="">
                        <FrequentlyAsked key={component.id} rutaRelativa={rutaRelativa} contenidoH2={contenidoH2}  />
                    </div>
                )

                default:
                return null;
        }
        }
    };

    return (
        <Fragment>
            {loading ? (
                <div className="content-loader">
                    <span className="loader"></span>
                </div>
            ): (
                <div>
                    <Header />
                    <Request />

                    <div className="contenedor">
                        {getPagesComponents(1).map(item => renderComponent(item))}
                        {getPagesComponents(2).map(item => renderComponent(item))}
                        {getPagesComponents(3).map(item => renderComponent(item))}
                        {getPagesComponents(4).map(item => renderComponent(item))}
                        {getPagesComponents(5).map(item => renderComponent(item))}
                        {getPagesComponents(6).map(item => renderComponent(item))}
                        {getPagesComponents(7).map(item => renderComponent(item))}
                        
                    </div>
                </div>
            )}
        </Fragment>
    );
}

export default Home;
