import React from 'react'

function Chat() {
    return(
        <div className='chat margin-0-auto'>

            <p className='chat__fecha centrar-texto'>September 23</p>

            <div className='mensajes'>

                <div className='bot'>
                    <img src='/img/chat.svg' alt='chat' />

                    <div className='bot__mensaje msg-btn'>
                        <p className='no-margin'>Hello! I'm a search bot. How can I help?</p>
                    </div>

                    <p className='bot__hora'>13:21</p>
                </div>

                <div className='usser'>
                    <p className='usser__hora'>13:22</p>

                    <div className='usser__mensaje'>
                        <p className='no-margin'>I would like to know what services you provide for banks?</p>
                    </div>
                </div>

                <div className='bot'>
                    <img src='/img/chat.svg' alt='chat' />

                    <div className='msg-btn'>
                        <div className='bot__mensaje'>
                            <p className='no-margin'>Here is a list of services for banks. If you want to know more about some services, select them.</p>


                        </div>
                        <div className='bot__buttons'>
                            <button type='button' className='bot__button boton boton--naranja'>Operational assistance</button>
                            <button type='button' className='bot__button boton boton--naranja'>Accounting</button>
                            <button type='button' className='bot__button boton boton--naranja'>Salary calculation</button>
                            <button type='button' className='bot__button boton boton--naranja'>Accounting</button>
                            <button type='button' className='bot__button boton boton--naranja'>Operational assistance</button>
                            <button type='button' className='bot__button boton boton--naranja'>Vedi altro</button>
                        </div>
                    </div>

                    <p className='bot__hora'>13:22</p>
                </div>

                <div className='bot'>
                    <img src='/img/chat.svg' alt='chat' />

                    <div className='msg-btn'>

                        <div className='bot__mensaje'>
                            <p className='no-margin'>Here is a list of services for banks. If you want to know more about some services, select them.</p>

                            <div className='bot__class'>
                                <p className='no-margin'>Obtaining a license for banking products</p>

                                <div className='bot__class__svg'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="8" viewBox="0 0 5 8" fill="none">
                                        <path d="M4.35355 4.35355C4.54882 4.15829 4.54882 3.84171 4.35355 3.64645L1.17157 0.464466C0.976311 0.269204 0.659728 0.269204 0.464466 0.464466C0.269204 0.659728 0.269204 0.976311 0.464466 1.17157L3.29289 4L0.464466 6.82843C0.269204 7.02369 0.269204 7.34027 0.464466 7.53553C0.659728 7.7308 0.976311 7.7308 1.17157 7.53553L4.35355 4.35355ZM3 4.5H4V3.5H3V4.5Z" fill="#29235C"/>
                                    </svg>
                                </div>
                            </div>

                            <div className='bot__class'>
                                <p className='no-margin'>Renewal of a license for banking products, support and assistance with paperwork</p>

                                <div className='bot__class__svg'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="8" viewBox="0 0 5 8" fill="none">
                                        <path d="M4.35355 4.35355C4.54882 4.15829 4.54882 3.84171 4.35355 3.64645L1.17157 0.464466C0.976311 0.269204 0.659728 0.269204 0.464466 0.464466C0.269204 0.659728 0.269204 0.976311 0.464466 1.17157L3.29289 4L0.464466 6.82843C0.269204 7.02369 0.269204 7.34027 0.464466 7.53553C0.659728 7.7308 0.976311 7.7308 1.17157 7.53553L4.35355 4.35355ZM3 4.5H4V3.5H3V4.5Z" fill="#29235C"/>
                                    </svg>
                                </div>
                            </div>

                            <div className='bot__class'>
                                <p className='no-margin'>Development of unique banking products and services, support, promotion, support of unique banking products</p>

                                <div className='bot__class__svg'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="8" viewBox="0 0 5 8" fill="none">
                                        <path d="M4.35355 4.35355C4.54882 4.15829 4.54882 3.84171 4.35355 3.64645L1.17157 0.464466C0.976311 0.269204 0.659728 0.269204 0.464466 0.464466C0.269204 0.659728 0.269204 0.976311 0.464466 1.17157L3.29289 4L0.464466 6.82843C0.269204 7.02369 0.269204 7.34027 0.464466 7.53553C0.659728 7.7308 0.976311 7.7308 1.17157 7.53553L4.35355 4.35355ZM3 4.5H4V3.5H3V4.5Z" fill="#29235C"/>
                                    </svg>
                                </div>
                            </div>

                        </div>

                        <div className='bot__buttons'>
                            <button type='button' className='bot__button boton boton--naranja'>Show what else was found</button>
                            <button type='button' className='bot__button boton boton--naranja'>Back</button>
                        </div>

                    </div>

                    <p className='bot__hora'>13:22</p>
                </div>
                
            </div>

            

            <input type="text" className="search-input" placeholder="Ask anything..." />

        </div>
    )
}
export default Chat