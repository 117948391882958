import React, { useContext, useEffect } from 'react'
import { PageContext } from '../../../context/pageContext'
import { useNavigate } from 'react-router-dom'

function IdleService() {
    const [ online24 ] = useContext(PageContext)
    const navigate = useNavigate()
    useEffect(() => {
        if(online24.isActive !== undefined && online24.isActive === true) {
            navigate('/')
        }
    }, [online24])
    return(
        <div className='inactive'>
            <h2 className='centrar-texto'>Service <span className='span-h2--azul'>inactive</span> at the moment :(</h2>
            <img
                src='/img/img4.svg'
                alt="answer"
            />
        </div>
    )
} 

export default IdleService